import { ACTIVE_ELEMENT_COMMENT, ACTIVE_SCREEN_COMMENT, ALL_PROJECTS_SHARED_MEMBERS_LIST, CHATBOT_CLOSURE, ELEMENT_COMMENT_COUNT, MENTION_LIST, OPEN_COMMENT_BUILDER, OPEN_COMMENT_LIST, OPEN_THREADES_ON_REDIRECT, RESOLVE_COMMENT_LIST, SHARED_MEMBERS_LIST, UNREAD_COUNT_COMMENT, UPDATE_ELEMENT_COUNT } from "../types/types"

const initialState = {
    sharedMembersList: [],
    activeElement: null,
    updateElementCount: false,
    activeScreen: null,
    mentionList: [],
    openCommentList: [],
    resolvedCommentList: [],
    allProjectSharedMemberList: [],
    unreadCountComment: 0,
    elementCommentCountGlobal: 0,
    chatbotClosureState: false,
    openCommentInBuilder: false,
    openThreadsOnRedirect: false
}

const commentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHARED_MEMBERS_LIST:
            return {
                ...state,
                sharedMembersList: action.payload,
            }
        case ACTIVE_ELEMENT_COMMENT:
            return {
                ...state,
                activeElement: action.payload,
            }
        case UPDATE_ELEMENT_COUNT:
            return {
                ...state,
                updateElementCount: action.payload,
            }
        case ACTIVE_SCREEN_COMMENT:
            return {
                ...state,
                activeScreen: action.payload,
            }
        case MENTION_LIST:
            return {
                ...state,
                mentionList: action.payload,
            }
        case OPEN_COMMENT_LIST:
            return {
                ...state,
                openCommentList: action.payload,
            }
        case RESOLVE_COMMENT_LIST:
            return {
                ...state,
                resolvedCommentList: action.payload,
            }
        case ALL_PROJECTS_SHARED_MEMBERS_LIST:
            return {
                ...state,
                allProjectSharedMemberList: action.payload,
            }
        case UNREAD_COUNT_COMMENT:
            return {
                ...state,
                unreadCountComment: action.payload,
            }
        case ELEMENT_COMMENT_COUNT:
            return {
                ...state,
                elementCommentCountGlobal: action.payload,
            }
        case CHATBOT_CLOSURE:
            return {
                ...state,
                chatbotClosureState: action.payload,
            }
        case OPEN_COMMENT_BUILDER:
            return {
                ...state,
                openCommentInBuilder: action.payload,
            }
        case OPEN_THREADES_ON_REDIRECT:
            return {
                ...state,
                openThreadsOnRedirect: action.payload,
            }
        default:
            return state
    }
}

export {
    commentReducer
}