import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap'
import LoveIcon from '../../assests/icons/love-icon.svg'
import VerySadIcon from '../../assests/icons/very-sad-icon.svg'
import SadIcon from '../../assests/icons/sad-icon.svg'
import HappyIcon from '../../assests/icons/happy-icon.svg'
import CrossIcon from '../../assests/icons/cancel.svg'
import GreatIcon from '../../assests/icons/great-icon.svg'
import SubmitSuccess from '../../assests/icons/submit-icon.svg'
import ExportLoad from '../../assests/images/export-load.gif'
import service from '../../utils/service';
import { apiEndpoints } from '../../utils/constants/apiEndpoints';


const Feedback = ({ setFeedback, chatId, setShowChat, resetChat }) => {

    const emojiText = ['Disappointed', 'Confused', 'Pleased', 'Great', 'Loved It'];

    const emojiImage = [VerySadIcon, SadIcon, HappyIcon, GreatIcon, LoveIcon]

    const [emoji, setEmoji] = useState(0)


    const [submitForm, setSubmitForm] = useState();

    const [loading, setLoading] = useState(false);

    const submitFeedback = () => {
        setLoading(true)
        let obj = {
            rating: emoji
        }
        service.put(`${apiEndpoints.CHATBOT_CONVERSATIONS}/${chatId}`, obj, { progress: false }).then((res) => {
            setLoading(false)
            setSubmitForm(true)
            setTimeout(() => {
                resetChat()
                setFeedback(false)
                setShowChat(false)
            }, 2000)
        }).catch((err) => {

        })
    }

    return (
        <>
            <div className='overlay'></div>
            <div className='feedback'>
                {!submitForm ? <div>
                    <img draggable={false} className="cross-icon pointer" src={CrossIcon} width="24" alt="cross-icon" onClick={() => { resetChat(); setFeedback(false); }} />

                    <div>

                        <div className="feedback-text">
                            <p className='heading'>Share your feedback</p>
                            <p className='helper-text'>We hope you had a great experience with our chatbot today. Would you mind rating us using an emoji?</p>
                        </div>
                        <div className='emoji-section' style={{ marginTop: emoji ? 10 : 20 }}>
                            <p className='emoji-text'>{emoji ? emojiText[emoji -1] + '!' : ''}</p>
                            <div className='emoji-icons-div ' style={{ gap: emoji ? 40 : 30 }}>

                                {
                                    emojiText && emojiText.map((item, index) => {
                                        return <img draggable={false} src={emojiImage[index]} width="30" className={`emojis ${(emoji == index + 1 ? 'active' : 'inactive')}`} alt="emoji" onClick={() => { setEmoji(index + 1) }} />
                                    })
                                }

                            </div>
                        </div>


                        <Button className={`small-btn  submit-feedback ${submitForm ? " active" : ""}  ${emoji ? "" : "disabled"}`} color="primary" style={{ width: "50% !important" }} onClick={() => { submitFeedback() }} >
                            {loading ? <img draggable={false} alt="" src={ExportLoad} className="feedback-load" /> : 'Submit feedback'}
                        </Button>


                    </div>

                </div> : <>
                    <div className='feedback-sucess'>
                        <img draggable={false} className="cross-icon pointer" src={CrossIcon} width="24" alt="cross-icon" onClick={() => { resetChat(); setFeedback(false) }} />

                        <img draggable={false} src={SubmitSuccess} width="50" alt="success-icon" className="tick" />
                        <div>
                            <p className='heading'>Feedback submitted!</p>
                            <p className='helper-text'>Thanks for helping us improve our service. We appreciate your feedback!</p>

                        </div>

                    </div>
                </>
                }

            </div>

        </>
    );
}

export default Feedback;