import { GET_PLANS, RECOMMENDED_PLAN, UPGRADE_POPUP, USER_PROFILE, USER_SUBSCRIPTION, IS_PAID_SUBSCRIPTION, USER_FEATURE, PRO_TEMPLATES, USER_SESSION, TRIAL_SUCCESS, IS_TRIAL_CONSUMED, IS_TRIAL_MEMBER, UNREAD_COUNT, FEEDBACK_POPUP, FEEDBACK_CHECK_401 } from '../types/types'

const initialState = {
    userDetails: {},
    subscription: {},
    plans: [],
    recommendedPlan: {},
    upgradePopup: false,
    isPaidSubscription: false,
    features: {},
    proTemplates: [],
    userSession: false,
    trialSuccess: false,
    isTrialConsumed: false,
    isTrialMember: false,
    unreadCount: 0,
    isShowFeedbackPopup: false,
    check401feedback : true,
}

const userDetailsReducers = (state = initialState, action) => {
    switch (action.type) {
        case USER_PROFILE:
            return {
                ...state,
                userDetails: action.payload,
            }
        case USER_SUBSCRIPTION:
            return {
                ...state,
                subscription: action.payload,
            }
        case GET_PLANS:
            return {
                ...state,
                plans: action.payload,
            }
        case RECOMMENDED_PLAN:
            return {
                ...state,
                recommendedPlan: action.payload,
            }
        case UPGRADE_POPUP:
            return {
                ...state,
                upgradePopup: action.payload
            }
        case IS_PAID_SUBSCRIPTION:
            return {
                ...state,
                isPaidSubscription: action.payload
            }
        case IS_TRIAL_CONSUMED:
            return {
                ...state,
                isTrialConsumed: action.payload
            }
        case IS_TRIAL_MEMBER:
            return {
                ...state,
                isTrialMember: action.payload
            }
        case USER_FEATURE:
            return {
                ...state,
                features: action.payload
            }
        case PRO_TEMPLATES:
            return {
                ...state,
                proTemplates: action.payload
            }
        case USER_SESSION:
            return {
                ...state,
                userSession: action.payload
            }
        case TRIAL_SUCCESS:
            return {
                ...state,
                trialSuccess: action.payload
            }
        case UNREAD_COUNT:
            return {
                ...state,
                unreadCount: action.payload
            }
        case FEEDBACK_POPUP:
            return {
                ...state,
                isShowFeedbackPopup: action.payload
            }
        case FEEDBACK_CHECK_401:
            return {
                ...state,
                check401feedback: action.payload
            }
        default:
            return state
    }
}

export {
    userDetailsReducers
}

