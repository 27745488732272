import packageJson from '../../../package.json'
import { localStorageKeys } from '../constants/localStorageKeys';
import { clearLocalStorage } from './localStorage';

/** Changes:
 * Added error handling using try-catch blocks to ensure the application does not crash due to unexpected errors.
 * Cleared the local storage to remove any potentially corrupted or outdated data.
 * Updated the reload method to use the current standard. The previous method, reload(true), was deprecated and has been replaced with reload().
 */
export const caching = async () => {
    try {
        const version = sessionStorage.getItem(localStorageKeys.VERSION);
        if (version !== packageJson.version) {
            if ('caches' in window) {
                const cacheNames = await caches.keys();
                await Promise.all(cacheNames.map(name => caches.delete(name)));
            }

            // Clear local storage
            // clearLocalStorage();

            // Set the new version in local storage
            sessionStorage.setItem(localStorageKeys.VERSION, packageJson.version);

            // Reload the page
            window.location.reload();
        }
    } catch (error) {
        console.error('Error during caching process:', error);
    }
};

// Previous implementation of caching function
export const cachingOld = () => {
    let version = localStorage.getItem(localStorageKeys.VERSION);
    if (version != packageJson.version) {
        if ('caches' in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });

            // Makes sure the page reloads. Changes are only visible after you
            window.location.reload(true);
        }

        // clearLocalStorage()
        localStorage.setItem(localStorageKeys.VERSION, packageJson.version);
    }
};