import React, { useEffect } from "react";
import { Button, Modal, ModalBody } from 'reactstrap'
import { connect, useDispatch } from 'react-redux'
import { checkUserSession, destroySession } from "../redux/actions/userAction";
import { routesConstants } from "../utils/constants/routes";
import { clearLocalStorage } from "../utils/helperFunctions/localStorage";

function UserSession({
    userSession
}) {

    let dispatch = useDispatch();

    const redirecttoLogin = () => {

        clearLocalStorage()
        dispatch(checkUserSession(false))
        dispatch(destroySession())
        window.location.href = routesConstants.HOME
    }

    return (
        <Modal isOpen={userSession} centered className="user-session-modal">
            <ModalBody >
                <div className="session-div">
                    <h3>User session expired</h3>
                    <p>We're sorry, your session has expired. Please log in again.</p>
                    <Button className="prime-btn " color="primary" onClick={() => redirecttoLogin()}>Login</Button>
                    {/* <Button className="prime-btn " color="primary" onClick={() => dispatch(checkUserSession(true))}>setFalse</Button> */}
                </div>
            </ModalBody>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        ...state,
        userSession: state.userDetailsReducers.userSession,
    }
}

export default connect(mapStateToProps)(UserSession)