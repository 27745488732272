import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllProjectsSharedMembersList,
  getRoleOfUser,
  getSharedMemberList,
} from "./commentsThunks";
import {
  CommentD,
  ElementCommentD,
  MentionListD,
  ScreenCommentD,
  SharedMemberD,
  ProjectMemberD,
} from "./commentsTypes";

type CommentStateD = {
  sharedMembersList: ProjectMemberD[];
  activeElement: ElementCommentD | null;
  updateElementCount: boolean;
  activeScreen: ScreenCommentD | null;
  mentionList: MentionListD[] | any[];
  openCommentList: CommentD[];
  resolvedCommentList: CommentD[];
  allProjectSharedMemberList: SharedMemberD[];
  unreadCountComment: number;
  elementCommentCountGlobal: number;
  chatbotClosureState: boolean;
  openCommentInBuilder: boolean;
  openThreadsOnRedirect: any;
};

const initialState: CommentStateD = {
  sharedMembersList: [],
  activeElement: null,
  updateElementCount: false,
  activeScreen: null,
  mentionList: [],
  openCommentList: [],
  resolvedCommentList: [],
  allProjectSharedMemberList: [],
  unreadCountComment: 0,
  elementCommentCountGlobal: 0,
  chatbotClosureState: false,
  openCommentInBuilder: false,
  openThreadsOnRedirect: false,
};

const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    resetCommentsState: () => initialState,
    setSharedMembersList(state, action: PayloadAction<ProjectMemberD[]>) {
      state.sharedMembersList = action.payload;
    },
    setActiveElement(state, action: PayloadAction<ElementCommentD | null>) {
      state.activeElement = action.payload;
    },
    setUpdateElementCount(state, action: PayloadAction<boolean>) {
      state.updateElementCount = action.payload;
    },
    setActiveScreen(state, action: PayloadAction<ScreenCommentD>) {
      state.activeScreen = action.payload;
    },
    setMentionList(state, action: PayloadAction<MentionListD[]>) {
      state.mentionList = action.payload;
    },
    setOpenCommentList(state, action: PayloadAction<CommentD[]>) {
      state.openCommentList = action.payload;
    },
    setResolvedCommentList(state, action: PayloadAction<CommentD[]>) {
      state.resolvedCommentList = action.payload;
    },
    setAllProjectSharedMemberList(
      state,
      action: PayloadAction<SharedMemberD[]>
    ) {
      state.allProjectSharedMemberList = action.payload;
    },
    setUnreadCountComment(state, action: PayloadAction<number>) {
      state.unreadCountComment = action.payload;
    },
    setElementCommentCountGlobal(state, action: PayloadAction<number>) {
      state.elementCommentCountGlobal = action.payload;
    },
    setChatbotClosureState(state, action: PayloadAction<boolean>) {
      state.chatbotClosureState = action.payload;
    },
    setOpenCommentInBuilder(state, action: PayloadAction<boolean>) {
      state.openCommentInBuilder = action.payload;
    },
    setOpenThreadsOnRedirect(state, action: PayloadAction<any>) {
      state.openThreadsOnRedirect = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSharedMemberList.fulfilled, (state, action) => {
      state.sharedMembersList = action.payload.res;
      state.mentionList = action.payload.members;
    });
    builder.addCase(getRoleOfUser.fulfilled, (state, action) => {
      state.sharedMembersList = action.payload.res;
      state.mentionList = action.payload.members;
    });
    builder.addCase(
      getAllProjectsSharedMembersList.fulfilled,
      (state, action) => {
        state.allProjectSharedMemberList = action.payload;
      }
    );
  },
});

export const {
  resetCommentsState,
  setSharedMembersList,
  setActiveElement,
  setUpdateElementCount,
  setActiveScreen,
  setMentionList,
  setOpenCommentList,
  setResolvedCommentList,
  setAllProjectSharedMemberList,
  setUnreadCountComment,
  setElementCommentCountGlobal,
  setChatbotClosureState,
  setOpenCommentInBuilder,
  setOpenThreadsOnRedirect,
} = commentSlice.actions;

export default commentSlice.reducer;
