import { ViewD } from "@/redux-toolkit/features/userDetails/userDetailsTypes";
import { ThemeD } from "@/views/mokkupBuilder/components/modules/types";
import {
  CustomThemeListD,
  ThemeListD,
} from "@/views/mokkupBuilder/components/tools/Sidebar/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultElementD } from "./mokkupBuilderTypes";

type ChangeElementThemeD = {
  tab: string | null;
  elementTheme: boolean;
};

export type UpdateDetailsStateD = {
  view: ViewD;
  popupId: string;
  theme: ThemeD;
  isTableResize: boolean;
  defaultElements: DefaultElementD[];
  columnToTarget: string;
  isStacked100: boolean;
  themeList: ThemeListD;
  customThemeListGlobal: CustomThemeListD;
  isDestroyEnabled: boolean;
  currentImage: string;
  dropTarget: boolean;
  showTemplateModal: boolean;
  tutorialsUseTemplate: boolean;
  showPreview: boolean;
  isTooltipDisabled: boolean;
  changeHeight: boolean;
  isSetDashboardTitle: boolean;
  isMapLayoutSave: boolean;
  changeElementTheme: ChangeElementThemeD;
};

export const initialUpdateDetailState: UpdateDetailsStateD = {
  view: {
    button: false,
    charts: false,
    dropdown: false,
    filter: false,
    hierarchyTable: false,
    id: undefined,
    image: false,
    kpi: false,
    style: false,
    table: false,
    textbox: false,
    title: false,
  },
  popupId: "",
  theme: {
    isChanged: false,
    id: 0,
    primary: "",
    palette: [],
    contrast: [],
  },
  isTableResize: false,
  defaultElements: [],
  columnToTarget: "",
  isStacked100: false,
  themeList: {
    colors: [],
    themes: [],
    count: 0,
    current_page: 0,
    next_page: null,
    previous_page: null,
    total_pages: 0,
  },
  customThemeListGlobal: {
    favourites: [],
    customColors: [],
    count: 0,
    current_page: 0,
    next_page: null,
    previous_page: null,
    total_pages: 0,
  },
  isDestroyEnabled: true,
  currentImage: "",
  dropTarget: false,
  showTemplateModal: false,
  tutorialsUseTemplate: false,
  showPreview: false,
  isTooltipDisabled: false,
  changeHeight: true,
  isSetDashboardTitle: false,
  isMapLayoutSave: false,
  changeElementTheme: {
    tab: null,
    elementTheme: false,
  },
};

export const updateDetailsSlice = createSlice({
  name: "updateDetails",
  initialState: initialUpdateDetailState,
  reducers: {
    resetUpdateDetailsState: () => initialUpdateDetailState,
    updateTheme(state, action: PayloadAction<ThemeD>) {
      state.theme = action.payload;
    },
    updateDetailsView(state, action: PayloadAction<ViewD>) {
      state.view = action.payload;
    },
    setPopupId(state, action: PayloadAction<string>) {
      state.popupId = action.payload;
    },
    setTableResize(state, action: PayloadAction<boolean>) {
      state.isTableResize = action.payload;
    },
    setDefaultElements(state, action: PayloadAction<DefaultElementD[]>) {
      state.defaultElements = action.payload;
    },
    setColumnToTarget(state, action: PayloadAction<string>) {
      state.columnToTarget = action.payload;
    },
    setStacked100(state, action: PayloadAction<boolean>) {
      state.isStacked100 = action.payload;
    },
    updateThemeList(state, action: PayloadAction<ThemeListD>) {
      state.themeList = action.payload;
    },
    updateCustomThemeList(state, action: PayloadAction<CustomThemeListD>) {
      state.customThemeListGlobal = action.payload;
    },
    enableDestroy(state, action: PayloadAction<boolean>) {
      state.isDestroyEnabled = action.payload;
    },
    setCurrentImage(state, action: PayloadAction<string>) {
      state.currentImage = action.payload;
    },
    updateDropTarget(state, action: PayloadAction<boolean>) {
      state.dropTarget = action.payload;
    },
    showTemplateModal(state, action: PayloadAction<boolean>) {
      state.showTemplateModal = action.payload;
    },
    setTutorialsUseTemplate(state, action: PayloadAction<boolean>) {
      state.tutorialsUseTemplate = action.payload;
    },
    setShowPreview(state, action: PayloadAction<boolean>) {
      state.showPreview = action.payload;
    },
    disableTooltip(state, action: PayloadAction<boolean>) {
      state.isTooltipDisabled = action.payload;
    },
    setDashboardTitle(state, action: PayloadAction<boolean>) {
      state.isSetDashboardTitle = action.payload;
    },
    changeHeight(state, action: PayloadAction<boolean>) {
      state.changeHeight = action.payload;
    },
    mapLayoutSave(state, action: PayloadAction<boolean>) {
      state.isMapLayoutSave = action.payload;
    },
    changeElementTheme(state, action: PayloadAction<ChangeElementThemeD>) {
      state.changeElementTheme = action.payload;
    },
  },
});

export const {
  resetUpdateDetailsState,
  updateTheme,
  updateDetailsView,
  setPopupId,
  setTableResize,
  setDefaultElements,
  setColumnToTarget,
  setStacked100,
  updateThemeList,
  updateCustomThemeList,
  enableDestroy,
  setCurrentImage,
  updateDropTarget,
  showTemplateModal,
  setTutorialsUseTemplate,
  setShowPreview,
  disableTooltip,
  setDashboardTitle,
  changeHeight,
  mapLayoutSave,
  changeElementTheme,
} = updateDetailsSlice.actions;

export default updateDetailsSlice.reducer;
