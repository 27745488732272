import React, { useState, useEffect, useRef } from "react";
import TheBot from "../../assests/images/the-bot.svg";
import BackArrowIcon from "../../assests/icons/back-arrow-icon.svg";
import SendMsgIcon from "../../assests/icons/send-msg-icon.svg";
import ConnectionLostIcon from "../../assests/icons/connection-lost-icon.svg";
import Load from "../../assests/images/load2.gif";
import Feedback from "./feedback";
import service from '../../utils/service'
import { apiEndpoints } from "../../utils/constants/apiEndpoints";
import LazyLoader from '../lazyLoading/shimmerGrid';
import moment from "moment";
import { localStorageKeys } from "../../utils/constants/localStorageKeys";

import ChatMinimise from '../../assests/icons/chat-minimise.svg'
import Add from '../../assests/images/plus.svg'

import LinkPreview from "./linkPreview";
import { useDispatch } from "react-redux";
import { TextForClosing } from "../../utils/constants/chatbotConst";


export default function Chat({
    setShowChat,
    setFeedback,
    feedback,
    newChat,
    showchat,
    setNewChat,
    reportingBug,
    oldChat,
    setOldChat,
    status,
    chatId,
    setChatId,
    setStatus,
    showChatbot,
    setShowChatbot,
    unread,
}) {

    const regex = /https?:\/\/[^\s.]+(?:\.[^\s.]+)*/g;

    const dispatch = useDispatch()

    const [error, setError] = useState(false);

    const [typing, setTyping] = useState(false);

    const [initialBlock, setInitialBlock] = useState(false)

    const [chatStatus, setChatStatus] = useState(false);

    const [chatArray, setChatArray] = useState([])

    const [loader, setLoader] = useState(true);

    const [disabled, setDisabled] = useState(false)

    const [disableOptions, setDisableOptions] = useState(false)

    const [userMessage, setUserMessage] = useState('')

    const [tryAgain, setTryAgain] = useState({ func: '', params: '' })

    const [title, setTitle] = useState(true);

    const [showNewChatButton, setShowNewChatButton] = useState(false);



    const currentChat = localStorage.getItem(localStorageKeys.CURRENT_CHAT_ID)


    const handleMultiClick = () => {
        setDisableOptions(true)
        setTimeout(() => {
            setDisableOptions(false)
        }, 1000);
    }

    const groupChatByDate = (arr) => {
        let dateHistory = []
        let chatHistory = []

        arr.forEach(element => {
            if (!(dateHistory.includes(moment.utc(element.modified_at).local().format('L')))) {
                dateHistory.push(moment.utc(element.modified_at).local().format('L'))
            }
        });

        dateHistory.forEach(element => {
            chatHistory.push({
                date: moment(element).format('dddd') + ", "
                    + moment(element).format("MMMM D") + ", "
                    + moment(element).format("YYYY"),
                chat: arr.filter(item => element == moment.utc(item.modified_at).local().format('L'))
            })
        })

        setChatArray(chatHistory)
    }

    const handleSendMessage = (e, click) => {
        if (!click) {
            if (e.code == "Enter" && !disabled) {
                e.preventDefault()
                sendDynamicMessage(title ? true : false)
                setTitle(false)
            }
        }
        else {
            if (!disabled) {
                sendDynamicMessage(title ? true : false);
                setDisabled(true)
                setTitle(false)

            }
        }
    }


    const initiateChat = () => {
        setTyping(true)
        setInitialBlock(true)
        service.post(apiEndpoints.CHATBOT_CONVERSATIONS, {}, { progress: false }).then((res) => {
            setTimeout(() => {
                setChatId(res.content.id)
                localStorage.setItem(localStorageKeys.CURRENT_CHAT_ID, res.content.id)
                setChatStatus(res.content.status)
                setLoader(false)
                setTryAgain({ func: '', params: '' })
                sendNewMessage(reportingBug ?'Report a Bug':'Ask me anything', res.content.id, false)
                setError(false)
            }, 1000);

        }).catch((error) => {
            setLoader(false)
            setError(true)
            setTryAgain({ func: 'initiateChat', params: '' })
        })
    };

    const updateChat = () => {
        // if (unread && chatStatus !== 'Closed') {
            let obj = {
                "read_ids": ["*"],
            }
            service.put(`${apiEndpoints.CHATBOT_CONVERSATIONS}/${chatId}`, obj, { progress: false }).then((res) => {
                setChatStatus(res.content.status)
                if (res?.content?.title && res?.content?.title?.length) {
                    setTitle(false)
                }
                setError(false)

            }).catch((error) => {
            })
        // }

    }

    const closeChat = (close = false) => {
        let obj = {
            "status": "Closed",
            "read_ids": ["*"],
        }
        service.put(`${apiEndpoints.CHATBOT_CONVERSATIONS}/${chatId}`, obj, { progress: false }).then((res) => {
            setChatStatus(res.content.status)
            setError(false)
            setTimeout(() => {
                setFeedback(true)

            }, 800);

        }).catch((error) => {
        })

    }

    const sendNewMessage = (content, idss, show = true, closechat = false, isTitle = false) => {
        setTyping(true)
        if (show) {
            setUserMessage(content)
        };
        service.post(`${apiEndpoints.CHATBOT_CONVERSATIONS}/${idss}/messages`,
            {
                "payload": [
                    {
                        "content": content,
                        "contentType": "PlainText"
                    }
                ],
                "read_ids": ["*"],
                is_title: isTitle,
            },
            { progress: false }
        ).then((res) => {
            if (closechat) {
                closeChat()
            }
            setUserMessage('')
            setInitialBlock(false)
            setError(false)
            groupChatByDate(res.content.slice(0, res.content.length - 1).reverse())
            setTyping(false)

        }).catch((error) => {
           
            if (error.response.data.code == 'conversation_closed') {
                setChatStatus('Closed')

            } else {
                setError(true)
            }
            setUserMessage('')
            setTyping(false)
        })
    }

    const sendMessage = (content, idss, show = true, closechat = false, isTitle = false) => {
        setTyping(true)
        if (show) {
            setUserMessage(content)
        };
        service.post(`${apiEndpoints.CHATBOT_CONVERSATIONS}/${chatId ? chatId : idss}/messages`,
            {
                "payload": [
                    {
                        "content": content,
                        "contentType": "PlainText"
                    }
                ],
                "read_ids": ["*"],
                is_title: isTitle,
            },
            { progress: false }
        ).then((res) => {
            if (closechat) {
                closeChat()
            }
            setUserMessage('')
            setInitialBlock(false)
            setError(false)
            groupChatByDate(res.content.slice(0, res.content.length - 1).reverse())
            setTyping(false)

        }).catch((error) => {
            if (error.response.data.code == 'conversation_closed') {
                setChatStatus('Closed')
                setShowNewChatButton(true)

            } else {
                setError(true)
            }
            setUserMessage('')
            setTyping(false)
        })
    }

    const fetchChat = (id) => {
        setLoader(true)
        service.get(`${apiEndpoints.CHATBOT_CONVERSATIONS}/${id}/messages`, { progress: false }).then((res) => {
            const arr = res.content.slice(0, res.content.length - 1).reverse()
            groupChatByDate(arr)
            setLoader(false)
            if (res?.content?.title && res?.content?.title?.length) {
                setTitle(false)
            }
            setError(false)
            setInitialBlock(false)
            setTryAgain({ func: '', params: '' })


        }).catch((error) => {
            setLoader(false)
            setError(true)
            setInitialBlock(false)
            setTryAgain({ func: 'fetchChat', params: id })

        })
    }

    const backToHome = () => {
        updateChat()
        setFeedback(false)
        setOldChat(); setNewChat(false); setShowChat(false); setStatus(''); setChatId(null); setChatStatus('')
    }
    const resetChat = () => {
        setOldChat(); setNewChat(false); setShowChat(false); setStatus(''); setChatId(null); setChatStatus('')

    }

    const sendDynamicMessage = (isTitle = false) => {
        let input = document.getElementById('sending-block').innerText
        sendMessage(input, '', true, false, isTitle);
        document.getElementById('sending-block').innerText = "";
    }

    const getHeightOfChatScreenBody = () => {
        let chatScreenContainerHeight = document.querySelector('.chatscreen-container') ? document.querySelector('.chatscreen-container').offsetHeight : 0
        let chatScreenSmallHeaderHeight = document.querySelector('.chatscreen-header-small') ? document.querySelector('.chatscreen-header-small').offsetHeight : 0
        let chatScreenBigHeaderHeight = document.querySelector('.header-chatbot') ? document.querySelector('.header-chatbot').offsetHeight : 0
        let msgSendContainerHeight = document.querySelector('.msg-send-container') ? document.querySelector('.msg-send-container').offsetHeight : 0
        let heightOfChatScreenBodyContainer = chatScreenContainerHeight - ((initialBlock ? chatScreenBigHeaderHeight : chatScreenSmallHeaderHeight) + msgSendContainerHeight);

        const chatScreenBodyContainer = document.querySelector(".chatscreen-body-container");
        const msgSendContainer = document.querySelector(".msg-send-container");
        if (chatScreenBodyContainer && msgSendContainer) {
            chatScreenBodyContainer.style.height = `${heightOfChatScreenBodyContainer}px`;
            chatScreenBodyContainer.scrollTop = chatScreenBodyContainer.scrollHeight;
        } else if (chatScreenBodyContainer) {
            chatScreenBodyContainer.style.height = `${chatScreenContainerHeight - (initialBlock ? chatScreenBigHeaderHeight : chatScreenSmallHeaderHeight)}px`;
            chatScreenBodyContainer.scrollTop = chatScreenBodyContainer.scrollHeight;
        }
    }

    const handleOptions = (disableOptions, value, id, show, closechat) => {
        if (disableOptions) {
            handleMultiClick();
            sendMessage(value, id, show, closechat);
        }
    }
    const callFunctionAgain = () => {
        if (tryAgain && tryAgain.func == 'initiateChat') {
            initiateChat()

        } else if (tryAgain && tryAgain.func == 'fetchChat') {
            fetchChat(tryAgain.params)
        }
    }
    const startNewChat = () => {
        setShowNewChatButton(false)
        setChatStatus('')
        setChatArray([])
        setChatId(null)
        initiateChat()

    }



    useEffect(() => {
        if (newChat && !chatId) {
            initiateChat()
        } else {
            setChatId(chatId != '' ? chatId : currentChat)
            fetchChat(chatId != '' ? chatId : currentChat)
        }
    }, [newChat, oldChat, showChatbot,reportingBug])

    useEffect(() => {
        setChatStatus(status)
    }, [status])



    useEffect(() => {
        var objDiv = document.getElementById("scroll-chatbot");
        setTimeout(() => {
            getHeightOfChatScreenBody();
            objDiv.scrollTo({ top: objDiv.scrollHeight, behaviour: 'smooth' })
        }, 100)
    }, [chatArray, initialBlock, userMessage])

    document.getElementById("sending-block")?.addEventListener("input", function (e) {
        setDisabled(e.target.innerText.trim().length ? false : true)
    }, false);

    useEffect(() => {
        if (chatId) {
            updateChat()

        }
    }, [showchat, chatId])


    useEffect(() => {
        if (document.getElementById('sending-block'))
            document.getElementById('sending-block').focus()
    }, [chatArray])


    useEffect(() => {

        if (chatArray && chatArray?.length && chatArray[0].chat && chatArray[0]?.chat?.length &&
            chatStatus !== "Closed" && chatArray[0]?.chat[chatArray[0]?.chat?.length - 1]?.payload[0]?.content == TextForClosing) {
            setTimeout(() => {
                closeChat()

            }, 3000);

        }

    }, [chatArray, chatStatus])




    return (
        <div className="chatscreen-container" >
            <div>
                {
                    initialBlock ?
                        <div className="header-chatbot">
                            <div className="chatscreen-header ">
                                <div className="d-flex justify-content-between" >
                                    <img draggable={false} src={BackArrowIcon} className="back-arrow-icon pointer" alt=" back arrow icon" onClick={() => { backToHome(); }} />
                                    <img draggable={false} src={ChatMinimise} className="back-arrow-icon pointer" alt=" back arrow icon" onClick={() => setShowChatbot(false)} />
                                </div>

                                <div>
                                    <img draggable={false} src={TheBot} className="the-bot" alt="the bot" />
                                </div>
                                <p className="bolder-text"> Mokkup <span>bot</span></p>
                                <p className="secondary-text">Need help with data visualizations, analytics, or science? We're happy to answer your questions.</p>
                            </div>

                            <div className="chatscreen-body">
                                <div className="bot-message-container">
                                    <img draggable={false} src={TheBot} className="the-bot" alt="the bot" />
                                    <div className="bot-chating">
                                        <div className="loader-size"><img draggable={false} src={Load} alt="chat loading" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        : <div className="chatscreen-header-connection-states">
                            <div className="chatscreen-header-small justify-content-between">
                                <div className="d-flex align-items-center" style={{ gap: 15 }}>
                                    <img draggable={false} src={BackArrowIcon} className="back-arrow-icon pointer" alt=" back arrow icon" onClick={() => backToHome()} />
                                    <div className="bot-icon-active-status" >
                                        <span className="the-bot" ><img draggable={false} src={TheBot} alt="the bot" /></span>
                                        <span style={{ backgroundColor: error ? "var(--error-red)" : "var(--success-green)" }} className="status-indicator"></span>
                                    </div>

                                    <div className="head-online-status">
                                        <p className="bolder-text"> Mokkup Bot</p>
                                        <p className="tertiary-text">{error ? 'Offline' : 'Online'}<span className="dot"></span></p>
                                    </div>
                                </div>
                                <img draggable={false} src={ChatMinimise} className="back-arrow-icon pointer" alt=" back arrow icon" onClick={() => setShowChatbot(false)} />


                            </div>


                            {
                                error && <div className="connection-states">
                                    <p style={{ backgroundColor: "var(--error-red)" }}>
                                        <img draggable={false} src={ConnectionLostIcon} alt="connectio status icon" />Oops! Something went wrong, <span style={{ textDecoration: 'underLine', cursor: 'pointer', fontWeight: 400 }} onClick={() => { callFunctionAgain() }} >Try Again!</span>
                                    </p>
                                </div>
                            }
                        </div>
                }


                <div className="chatscreen-body-container">
                    <div className="chatscreen-body">

                        {
                            oldChat && loader ?
                                <LazyLoader view="chatscreen-body-lazy" />
                                :
                                <>
                                    {
                                        chatArray && chatArray.length > 0 && chatArray.map((item, index) => {
                                            return <>
                                                <div className="line">
                                                    <span>
                                                        {item.date}
                                                    </span>
                                                </div>

                                                {
                                                    item.chat && item.chat.map((message, i) => {
                                                        return <>
                                                            {
                                                                message.is_bot_response ?
                                                                    <>
                                                                        <div className="bot-message-container">
                                                                            <div className="align-message-bot">
                                                                                <img draggable={false} src={TheBot} className="the-bot" alt="the bot" />
                                                                            </div>


                                                                            <div className="bot-chating">


                                                                                <>
                                                                                    {message && message?.payload?.map((messageItem, index) => <div className="bot-message" >
                                                                                        {(messageItem?.contentType == "ImageResponseCard" ? messageItem?.imageResponseCard.title : messageItem?.content).match(regex) ?

                                                                                            <>

                                                                                                <div className="bot-msg mb-2">

                                                                                                    <span> {messageItem?.content?.slice(0, messageItem?.content.indexOf(messageItem?.content?.match(regex)[0]))}</span>
                                                                                                    <a target="_blank" href={messageItem?.content?.match(regex)[0]}>{messageItem?.content?.match(regex)[0]}</a>
                                                                                                    <span> {messageItem?.content?.slice(messageItem?.content?.indexOf(messageItem?.content?.match(regex)[0]) + messageItem?.content?.match(regex)[0]?.length, messageItem?.content?.length)}</span>
                                                                                                </div>

                                                                                                <LinkPreview url={(messageItem?.contentType == "ImageResponseCard" ? messageItem?.imageResponseCard.title : messageItem?.content).match(regex)[0]} />

                                                                                            </>

                                                                                            : <div className="bot-msg">{messageItem?.contentType == "ImageResponseCard" ? messageItem?.imageResponseCard.title : messageItem?.content}

                                                                                            </div>}


                                                                                    </div>

                                                                                    )}

                                                                                    <span className="bot-response-time">Bot <span className="dot">&#x2022;</span>
                                                                                        {moment.utc(message?.modified_at).local().fromNow() == 'in a few seconds' ? 'a few seconds ago ' : moment.utc(message?.modified_at).local().fromNow()}
                                                                                    </span>
                                                                                </>


                                                                            </div>
                                                                        </div>

                                                                        {message && message?.payload?.map(messageItem => <div>
                                                                            {messageItem?.contentType == "ImageResponseCard" &&

                                                                                <div className="options">

                                                                                    {
                                                                                        messageItem?.imageResponseCard?.buttons && messageItem?.imageResponseCard?.buttons.map((buttonItem, index) => {
                                                                                            return <span style={(i != item.chat?.length - 1 || chatStatus == "Closed" || disableOptions) ? { pointerEvents: 'none' } : { cursor: 'pointer' }} onClick={() => { handleOptions(i == item.chat?.length - 1, buttonItem.value, '', true, buttonItem.text == 'No') }}>
                                                                                                {buttonItem.text}

                                                                                            </span>

                                                                                        })
                                                                                    }
                                                                                </div>}
                                                                        </div>

                                                                        )}

                                                                    </>
                                                                    : <>
                                                                        <div className="user-message-container">
                                                                            <div>{message?.payload[0].contentType == "ImageResponseCard" ? message?.payload[0].content?.imageResponseCard?.title : message?.payload[0].content}</div>

                                                                        </div>

                                                                    </>
                                                            }

                                                        </>
                                                    })
                                                }
                                            </>
                                        })
                                    }

                                    {
                                        userMessage && userMessage.length && <div className="user-message-container">
                                            <div>{userMessage}</div>
                                        </div>
                                    }
                                    {((typing && chatArray.length) || (chatStatus !== "Closed" && chatArray && chatArray.length && !(chatArray[0]?.chat[chatArray[0]?.chat?.length - 1]?.is_bot_response))) ? <div className="bot-message-container">
                                        <div className="align-message-bot">
                                            <img draggable={false} src={TheBot} className="the-bot" alt="the bot" />
                                        </div>

                                        <div className="bot-chating"> <div className="loader-size"><img draggable={false} src={Load} alt="chat loading" /></div></div> </div>
                                        : ''}
                                </>
                        }

                    </div>
                </div>
            </div>
            {((chatArray && chatArray.length && chatArray[0].chat.length > 0 && chatStatus !== "Closed") && chatArray[0]?.chat[chatArray[0]?.chat?.length - 1]?.payload?.findIndex(e => e?.contentType === 'ImageResponseCard') == -1) && chatArray[0]?.chat[chatArray[0]?.chat?.length - 1]?.is_bot_response && chatArray[0]?.chat[chatArray[0]?.chat?.length - 1]?.payload[0]?.content !== TextForClosing ? <div className="msg-send-container">
                <div className="msg-send" style={userMessage && userMessage.length ? { pointerEvents: 'none' } : {}}>
                    <div className="for-scrollbar">
                        <div className="msg" data-placeholder="Enter your message here..."  contenteditable={userMessage && userMessage.length ? false : "plaintext-only"} id="sending-block" onKeyDown={(e) => handleSendMessage(e)}
                        >
                        </div>
                    </div>
                    <img draggable={false} src={SendMsgIcon} alt="" className={disabled ? 'disabled' : ''} onClick={(e) => handleSendMessage(e, "click")} />
                </div>
            </div> : <div className="msg-send-container chat-border-container" style={{ borderTop: 'none' }}>
                <div className="msg-send">{chatStatus == "Closed" && <div className="chat-border"><span>Your conversation has ended</span></div>}</div>
            </div>}


            {showNewChatButton && <div className="create-new-container">
                <div className="create-new" onClick={() => { startNewChat() }}>
                    <img draggable={false} src={Add} alt="" />     Start a new convo

                </div>
            </div>}

            {
                feedback && <Feedback setFeedback={setFeedback} chatId={chatId} setShowChat={setShowChat} resetChat={resetChat} />
            }
        </div>
    );
}