import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { localStorageKeys } from './constants/localStorageKeys';
import { routesConstants } from './constants/routes';
import { caching } from './helperFunctions/caching';

interface MfaGuardProps extends RouteComponentProps {
  // Add any additional props here
}

interface MfaGuardState {
  // Add any state properties here if needed
}

export default function mfaGuard<P extends MfaGuardProps>( 
  ComposedComponent: React.ComponentType<P>
) {
  class AuthenticationMFA extends Component<P, MfaGuardState> {     
    componentDidMount() {
      caching();
      const authToken = localStorage.getItem(localStorageKeys.TEMPORARY_TOKEN);
      if (!authToken) {
        this.props.history.push(routesConstants.HOME);
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }
    
  function mapStateToProps(state: any) {
    return { 
      ...state
    };
  }

  return connect(mapStateToProps)(AuthenticationMFA);
}