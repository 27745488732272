import { ACTIVE_WORKSPACE, WORKSPACES_LIST, WORKSPACES_SUBSCRIPTION } from "../types/types";

const initialState = {
  activeWorkspace: {},
  workspacesList: [],
  requestCount: 0,
  subscription:{},
};

const workspaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_WORKSPACE:
      return {
        ...state,
        activeWorkspace: action.payload,
      };
      case WORKSPACES_LIST:
        return {
          ...state,
          workspacesList: action.payload,
        };
        case WORKSPACES_SUBSCRIPTION:
          return {
            ...state,
            subscription: action.payload,
          };

    case "SET_REQUEST_COUNT":
      return {
        ...state,
        requestCount: action.payload,
      };

    default:
      return state;
  }
};

export { workspaceReducer };
