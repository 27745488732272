import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type DashboardStateD = {
  currentTemplate: string;
  headerZindex: boolean;
  openChatbot: boolean;
};

const initialState: DashboardStateD = {
  currentTemplate: "Marketing",
  headerZindex: false,
  openChatbot: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setCurrentTemplate(state, action: PayloadAction<string>) {
      state.currentTemplate = action.payload;
    },
    setTemplatesHeaderZindex(state, action: PayloadAction<boolean>) {
      state.headerZindex = action.payload;
    },
    setOpenChatbot(state, action: PayloadAction<boolean>) {
      state.openChatbot = action.payload;
    },
  },
});

export const { setCurrentTemplate, setTemplatesHeaderZindex, setOpenChatbot } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
