export const baseURL = () => {
    if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'prod') {
        return process.env.REACT_APP_PROD_API_URL
    }
    else if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'qa') {
        return process.env.REACT_APP_QA_API_URL
    } else {
        return process.env.REACT_APP_DEV_API_URL
    }
}

export const socketURL = () => {
    if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'prod') {
        return process.env.REACT_APP_PROD_SOCKET_URL
    }
    else if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'qa') {
        return process.env.REACT_APP_QA_SOCKET_URL
    } else {
        return process.env.REACT_APP_DEV_SOCKET_URL
    }
}

export const websiteURL = () => {
    if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'prod') {
        return process.env.REACT_APP_WEBSITE_PROD
    }
    else if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'qa') {
        return process.env.REACT_APP_WEBSITE_QA
    } else {
        return process.env.REACT_APP_WEBSITE_DEV
    }
}

export const googleLoginId = () => {
    if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'prod') {
        return process.env.REACT_APP_GOOGLE_CLIENT_ID_PROD
    } else {
        return process.env.REACT_APP_GOOGLE_CLIENT_ID_DEV
    }
}
export const microsoftLoginId = () => {
    if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'prod') {
        return process.env.REACT_APP_MICROSOFT_CLIENT_ID_PROD
    } else {
        return process.env.REACT_APP_MICROSOFT_CLIENT_ID_DEV
    }
}
export const linkedInLoginId = () => {
    if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'prod') {
        return process.env.REACT_APP_LINKEDIN_CLIENT_ID_PROD
    } else {
        return process.env.REACT_APP_LINKEDIN_CLIENT_ID_DEV
    }
}
export const slackLoginId = () => {
    if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'prod') {
        return process.env.REACT_APP_SLACK_CLIENT_ID_PROD
    } else {
        return process.env.REACT_APP_SLACK_CLIENT_ID_DEV
    }
}

export const appUrl = () => {
    if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'prod') {
        return "https://app.mokkup.ai"
    }
    else if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'qa') {
        return "https://qa-app.mokkup.ai"
    } else {
        return "https://dev-app.mokkup.ai"
    }
}

export const cannyKey = (data) => {
    if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'prod') {
        switch (data) {
            case 'appid':
                return process.env.REACT_APP_CANNY_PROD_APPID
            case 'boardid':
                return process.env.REACT_APP_CANNY_PROD_BOARDID
            case 'apikey':
                return process.env.REACT_APP_CANNY_PROD_APIKEY
        }
    }
    else {
        switch (data) {
            case 'appid':
                return process.env.REACT_APP_CANNY_DEV_APPID
            case 'boardid':
                return process.env.REACT_APP_CANNY_DEV_BOARDID
            case 'apikey':
                return process.env.REACT_APP_CANNY_DEV_APIKEY
        }
    }
}

export const cannyLinks = (data) => {
    if (process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'prod') {
        switch (data) {
            case 'templates':
                return "https://mokkupai.canny.io/template-requests"
            case 'features':
                return "https://mokkupai.canny.io/feature-requests"
            case 'roadmap':
                return "https://mokkupai.canny.io/"
            case 'changelog':
                return "https://mokkupai.canny.io/changelog"
        }
    }
    else {
        switch (data) {
            case 'templates':
                return "https://mokkup.canny.io/template-request"
            case 'features':
                return "https://mokkup.canny.io/feature-requests"
            case 'roadmap':
                return "https://mokkup.canny.io/"
            case 'changelog':
                return "https://mokkup.canny.io/changelog"
        }
    }
}