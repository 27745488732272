import { PAGES_LIST, MAP_LAYOUT, UPDATE_LAYOUT, UPDATE_THEME, UPDATE_DETAILSVIEW, CURRENT_TEMPLATE, UNDO, REDO, POPUP_ID, TABLE_RESIZE, DEFAULT_ELEMENTS, COL_LAYOUT, STACKED_100, UPDATE_DATA, UPDATE_THEME_LIST, CURRENT_IMAGE, TUTORIALS_USE_TEMPLATE, ENABLE_DESTROY, SHOW_TEMPLATE_MODAL, RESET_REDO, RESET_UNDO, UPDATE_DROP_TARGET, TARGET_COLUMN, SHOW_PREVIEW, TEMPLATES_HEADER_ZINDEX, DISABLE_TOOLTIP, SET_DASHBOARD_TITLE, CHANGE_HEIGHT, MAP_LAYOUT_SAVE, OPEN_CHATBOT, CHANGE_ELEMENT_THEME, UPDATE_DASHBOARD_CUSTOMISATION, CUSTOM_THEME_LIST } from '../types/types'
import html2canvas from 'html2canvas'

// map layout 
export const mapLayout = (layout, type,action) => (dispatch) => {
    dispatch(preventSave(true))
    let a = layout
    if (type == 'new') {
        dispatch({
            type: MAP_LAYOUT,
            payload: [...layout]
        })
    }
    else {
        const layouts = JSON.parse(layout).filter(item => !item?.i?.includes('__dropping-elem__'))
        dispatch({
            type: MAP_LAYOUT,
            payload: []
        })
        setTimeout(() => {
            dispatch({
                type: MAP_LAYOUT,
                payload: [...layouts]
            })
        },0);
    }

    if(!action){
        setTimeout(() => {
            dispatch(preventSave(false))
        },500)
    }
}


// update layout
export const layoutChange = (layout, type, i) => (dispatch, getState) => {
    let { builderReducer: { layouts } } = getState()
    if (i != "'__dropping-elem__'") {
        if (type == 'remove') {
            let updated = layouts.filter(x => {
                return x.i != i;
            })
            dispatch({
                type: UPDATE_LAYOUT,
                payload: updated
            })
        }
        else {
            dispatch({
                type: UPDATE_LAYOUT,
                payload: [...layouts, layout]
            })
        }
    }
}

// Update details
export const updateData = (layout) => (dispatch, getState) => {
    let { builderReducer: { layouts } } = getState()
    const newLayout = layout.filter(item => !item?.i?.includes('__dropping-elem__'))
    dispatch(preventSave(true))
        dispatch({
            type: UPDATE_DATA,
            payload: [...newLayout]
        })
        
        setTimeout(() => {
            dispatch(preventSave(false))
        },100)
}

// update theme
export const updateThemeData = (data) => dispatch => {
    dispatch({
        type: UPDATE_THEME,
        payload: data
    })
}


//Update view
export const updateView = (val, id, styles) => dispatch => {
    let obj = { charts: false, kpi: false, table: false, filter: false, button: false, dropdown: false, title: false, id: '', style: false, hierarchyTable: false, image : false, textbox : false }
    if (val === "radialChart" || val === "columnChart" || val === 'scatter' || val === "bubble" || val === 'treemap' || val === 'heatmap' || val === 'funnel' || val === 'sankey'
        || val === 'hexgrid' || val === 'waterfall' || val === 'geomap' || val==="histogram" || val==="gauge" ) {
        obj.charts = true
    }
    if (val == 'hierarchyTable') {
        obj.hierarchyTable = true
    }
    if (val === "table") {
        obj.table = true
    }
    if (val === "kpi") {
        obj.kpi = true
    }
    if (val === "image") {
        obj.image = true
    }
    if (val === "textbox") {
        obj.textbox = true
    }
    if (val === "filter" || val === "dateFilter") {
        obj.filter = true
    }
    if (val === "dropdown") {
        obj.dropdown = true
    }
    if (val === "button") {
        obj.button = true
    }
    if (val === "titlelogo" || val === "title" || val === "logo") {
        obj.title = true
    }
    obj.id = id
    if (styles && styles != '') {
        obj.style = true
    }
    dispatch({
        type: UPDATE_DETAILSVIEW,
        payload: { ...obj }
    })
}

// delecte cols function
export const updateLayoutOnDelCol = (rows, cols, style, id) => (dispatch, getState) => {
    let { builderReducer: { layouts } } = getState()

    let final = [...layouts]

    let obj = final.find(item => item.i == id)
    let newFinal = final.filter(item => item.i !== id)

    if (obj) {
        obj.metaData.data.rowData = rows
        obj.metaData.data.headings = cols
        obj.metaData.data.styles = style
        obj.metaData.data.cols = cols.length
        obj.metaData.data.rows = rows.length

    }
    newFinal.push(obj);
    dispatch({
        type: COL_LAYOUT,
        payload: newFinal
    })
}

//current template
export const getTemplateCategory = (obj) => (dispatch) => {
    dispatch({
        type: CURRENT_TEMPLATE,
        payload: obj
    })
}


//Pages List
export const addPages = (data) => (dispatch) => {
    dispatch({
        type: PAGES_LIST,
        payload: data
    })
}

//theme List
export const addThemes = (data) => (dispatch) => {
    dispatch({
        type: UPDATE_THEME_LIST,
        payload: data
    })
}

//custom theme List
export const getCustomThemeList = (data) => (dispatch) => {
    dispatch({
        type: CUSTOM_THEME_LIST,
        payload: data
    })
}


//dashboard image url

export const getImage = (dashboardId, screenId, width, height) => dispatch => {
    return new Promise((resolve, reject) => {
        window.dispatchEvent(new Event('resize'));
        const input = document.getElementById('board-wrapper') ? document.getElementById('board-wrapper') : document.getElementById('emptyCanvas');
        html2canvas(input, {
            allowTaint: true, useCORS: true, scale: 2, foreignObjectRendering: false, width: width, height: height,
            onrendered: function (canvas) {
            }
        }).then((canvas) => {
            canvas.toBlob(function (blob) {
                // let objectURL = URL.createObjectURL(blob);
                var file = new File([blob], screenId+".png", { lastModified: new Date().getTime(), type: blob.type });
                var formData = new FormData()
                formData.append("image",file)
                // dispatch({
                //     type: CURRENT_IMAGE,
                //     payload: objectURL
                // })
                resolve(formData)

            });
        }).catch((err) => {
            reject("")
        })
    })
}


//checkZeroValue

export const checkZeroValue = (str) => {
    let s = false;
    let arr = str.split(/\W/);
    arr.forEach(function (number) {
        if (number == '0') {
            s = true
        }
    });
    return s;
}



// Undo
export const toUndo = () => (dispatch, getState) => {
    dispatch({
        type: UNDO,
        payload: ""
    })
}

// Redo
export const toRedo = () => (dispatch, getState) => {
    dispatch({
        type: REDO,
        payload: ""
    })
}
export const updateCustomisations = (data) => (dispatch) => {
     dispatch({
        type: UPDATE_DASHBOARD_CUSTOMISATION,
        payload: data
    })
}


// popupID
export const setPopupId = (data) => (dispatch) => {

    dispatch({
        type: POPUP_ID,
        payload: data
    })
}

export const toggleTableResize = (res) => dispatch => {
    dispatch({
        type: TABLE_RESIZE,
        payload: res
    })
}


export const toggleStackedChart = (res) => dispatch => {
    dispatch({
        type: STACKED_100,
        payload: res
    })
}

// Hex to rgba

export const hexToRgba = (hex, alpha) => dispatch => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

//timer

export function startTimer(duration, display) {
    var timer = duration, minutes, seconds;
    var timerId = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = " " + minutes + ":" + seconds;

        if (--timer < 0) {
            // timer = duration;
            display.textContent = "Resend";
        }
    }, 1000);
    setTimeout(() => { clearInterval(timerId); }, 91 * 1000);
}



//default elements
export const mapDefaultElements = (res) => dispatch => {
    dispatch({
        type: DEFAULT_ELEMENTS,
        payload: res
    })
}

//tutorials for use template
export const templateTutorials = (res) => dispatch => {
    dispatch({
        type: TUTORIALS_USE_TEMPLATE,
        payload: res
    })
}

//fin min, max of heatmap
export const minMaxData = (res) => dispatch => {
    let final = []
    if (res.length > 0) {
        let reducedArr = []
        res.forEach(element => {
            if(element){
                let max = []
                element.forEach(item => {
                    if(item){
                        if (typeof item === 'string') {
                            let rest = parseInt(item)
                            if (isNaN(rest)) {
                                // max.push(0)
                            }
                            else {
                                max.push(rest)
                            }
        
                        }
                        else {
                            max.push(item)
                        }
                    }
                });
                reducedArr.push(max)
            }
        });

        const max = Math.max(...[].concat(...reducedArr));
        const min = Math.min(...[].concat(...reducedArr));
        final.push(max)
        final.push(min)
        return final
    }
}

export const getLocalUrl = (url) => dispatch => {
    return fetch(url).then(r => r.blob()).then((res) => {
        let blobUrl = URL.createObjectURL(res);
        return blobUrl
    }).catch((err) => {
        return url
    })
}


export const convertToCurrencySystem = (labelValue, roundOff = 2) => dispatch => {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

        ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(roundOff) + " B"
        // Six Zeroes for Millions 
        : Math.abs(Number(labelValue)) >= 1.0e+6

            ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(roundOff) + " M"
            // Three Zeroes for Thousands
            : Math.abs(Number(labelValue)) >= 1.0e+3

                ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(roundOff) + " K"

                : Math.abs(Number(labelValue));

}

export const enableGeo = (data) => dispatch => {
    dispatch({ type: ENABLE_DESTROY, payload: data })
}

export const disableTooltip = (data) => dispatch => {
    dispatch({ type: DISABLE_TOOLTIP, payload: data })
}

export const setShowTemplateModal = (data) => dispatch => {
    dispatch({ type: SHOW_TEMPLATE_MODAL, payload: data })
}

export const  resetRedo = () => dispatch => {
    dispatch({
        type : RESET_REDO,
        payload : []
    })
}

export const  resetUndo = () => dispatch => {
    dispatch({
        type : RESET_UNDO,
        payload : []
    })
}

export const  updateDropTarget = (data) => dispatch => {
    dispatch({
        type : UPDATE_DROP_TARGET,
        payload : data
    })
}


export const  setTargetColumn = (data) => dispatch => {
    dispatch({
        type : TARGET_COLUMN,
        payload : data
    })
}

//action for new Preview

export const setShowPreview = (data) => dispatch => {
    dispatch({ type: SHOW_PREVIEW, payload: data })
}

//action for change element theme

export const elementTheme = (data) => dispatch => {
    dispatch({ type: CHANGE_ELEMENT_THEME, payload: data })
}

//map layout -no auto save

export const preventSave = (data) => dispatch => {
    dispatch({ type: MAP_LAYOUT_SAVE, payload: data })
}

//open chatbot

export const toggleChatbot = (data) => dispatch => {
    dispatch({ type: OPEN_CHATBOT, payload: data })
}

export const setChangeHeight = (data) => dispatch => {
    dispatch({ type: CHANGE_HEIGHT, payload: data })
}

// templates header z-index
export const updateZindex = (data) => dispatch => {
    dispatch({ type: TEMPLATES_HEADER_ZINDEX, payload: data })
}

// new Dashboard title
export const updateNewDashboardTitle = (data) => dispatch => {
    dispatch({ type: SET_DASHBOARD_TITLE, payload: data })
}

export const getGradientColors = (color1,color2,num) => dispatch => {
    function hex (c) {
        var s = "0123456789abcdef";
        var i = parseInt (c);
        if (i == 0 || isNaN (c))
          return "00";
        i = Math.round (Math.min (Math.max (0, i), 255));
        return s.charAt ((i - i % 16) / 16) + s.charAt (i % 16);
      }
      
      /* Convert an RGB triplet to a hex string */
      function convertToHex (rgb) {
        return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
      }
      
      /* Remove '#' in color hex string */
      function trim (s) { return (s.charAt(0) == '#') ? s.substring(1, 7) : s }
      
      /* Convert a hex string to an RGB triplet */
      function convertToRGB (hex) {
        var color = [];
        color[0] = parseInt ((trim(hex)).substring (0, 2), 16);
        color[1] = parseInt ((trim(hex)).substring (2, 4), 16);
        color[2] = parseInt ((trim(hex)).substring (4, 6), 16);
        return color;
      }
      
      function generateColor(colorStart,colorEnd,colorCount){
      
          // The beginning of your gradient
          var start = convertToRGB (colorStart);    
      
          // The end of your gradient
          var end   = convertToRGB (colorEnd);    
      
          // The number of colors to compute
          var len = colorCount;
      
          //Alpha blending amount
          var alpha = 0.0;
      
          var saida = [];
          
          for (let i = 0; i < len; i++) {
              var c = [];
              alpha += (1.0/len);
              
              c[0] = start[0] * alpha + (1 - alpha) * end[0];
              c[1] = start[1] * alpha + (1 - alpha) * end[1];
              c[2] = start[2] * alpha + (1 - alpha) * end[2];
      
              saida.push("#" + convertToHex (c)+"cc");
              
          }
          return saida;
      }      
      var tmp = generateColor(color1,color2,num ? num : 12);

      return tmp
}