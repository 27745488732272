import React, { useEffect, useState } from 'react';
import service from '../../utils/service';
import { apiEndpoints } from '../../utils/constants/apiEndpoints';
import OpenInNewTab from "../../assests/icons/open-in-new-tab-icon.svg";


const LinkPreview = ({
    url
}) => {

    const [metaContent, setMetaContent] = useState();

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);

    const fetchMetaTags = () => {
        setLoading(true)
        let data = {
            url: url
        }
        service.post(apiEndpoints.OG_META, data, { progress: false }).then(res => {
            setMetaContent(res.content)
            setError(false)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setError(true)
        })

    }

    useEffect(() => {
        fetchMetaTags()
    }, [url])

    return (
        <>

            {
                !error && <>
                    <div className="bot-messege-with-link">
                        <div className="illustration" style={loading ? { minHeight: 150 } : {}}>
                            <img draggable={false} className='pointer' onClick={() => window.open(url, "_blank")} src={metaContent?.image} alt="" />
                            <span className="link"><a href={url} target="_blank"><img draggable={false} src={OpenInNewTab} alt="" /></a></span>
                        </div>
                        <div className="content" style={loading ? { minHeight: 100 } : {}}>
                            <h3 style={{ whiteSpace: 'break-spaces' }}>{metaContent?.title}</h3>
                            <p>{metaContent?.description}</p>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default LinkPreview;

