import React from 'react';
import InvalidLinkIllustration from '../../../assests/images/invalid-link.svg';
import { Button } from 'reactstrap';
import secondaryPolygon from '../../../assests/images/secondaryPolygon.svg'
import dottedRectangle from '../../../assests/images/dottedRectangle.svg'
import dashedPolygon from '../../../assests/images/dashedPolygon.svg'
import Polygon from '../../../assests/images/Polygon8.png'
import BlueCurve from '../../../assests/images/blueCurve.png'
import { routesConstants } from '@/utils/constants/routes';
import { useHistory } from 'react-router-dom';

function InvalidLink({ errorMessage }) {
    const history = useHistory();

    const backToHome = () => {
        history.push(routesConstants?.DASHBOARD)
    }

    return (
        <div className='invalid-link-error-main-container'>
            <div className='invalid-link-error-wrapper'>
                <h3 className='invalid-link-error-title'>{errorMessage}</h3>
                <p className='invalid-link-error-description'>
                {"Please request the owner of the team to resend the invitation link!"}
                </p>
                <span><img draggable={false} src={secondaryPolygon} className="secondaryPolygon-img" alt="" /></span>
                <span><img draggable={false} src={dashedPolygon} className="dashedPolygon-img" alt="" /></span>
                <span><img draggable={false} src={dottedRectangle} className="dottedRectangle-img" alt="" /></span>
                <span><img draggable={false} src={Polygon} className="polygon-img" alt="" /></span>
                <span><img draggable={false} src={BlueCurve} className="blue-curve-img" alt="" /></span>
                <Button className="prime-btn invalid-link-error-button" color="primary" onClick={backToHome} >Back Home</Button>
                <img src={InvalidLinkIllustration} className='invalid-link-error-image' alt='Page not found' />
            </div>
        </div>
    );
}

export default InvalidLink;
