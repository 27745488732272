import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "@/utils/service";
import { apiEndpoints } from "@/utils/constants/apiEndpoints";
import {
  GET_FEATURES,
  GET_PLANS,
  GET_UNREAD_CONVO,
  GET_USER_INFO,
} from "@/redux-toolkit/types";
import {
  FeatureApiResponseD,
  FeaturesD,
  UserDetailsD,
} from "./userDetailsTypes";
import { setLocalStorageItem } from "@/utils/helperFunctions/localStorage";
import { localStorageKeys } from "@/utils/constants/localStorageKeys";
import { PlansResponseD } from "@/redux-toolkit/features/userDetails/userDetailsTypes";

export const getFeatures = createAsyncThunk<FeaturesD>(
  GET_FEATURES,
  async () => {
    const res = (await service.get(apiEndpoints.FEATURES)) as {
      content: FeatureApiResponseD;
    };
    return res.content.features;
  }
);

export const getUserInfo = createAsyncThunk(GET_USER_INFO, async () => {
  const res = (await service.get(apiEndpoints.ACCOUNTS_ME)) as {
    content: {
      profile: UserDetailsD;
    };
  };
  return res.content.profile;
});

export const getUnreadConvo = createAsyncThunk(GET_UNREAD_CONVO, async () => {
  const res = (await service.get(
    `${apiEndpoints.CHATBOT_CONVERSATIONS}?is_read=false`
  )) as {
    content: {
      count: number;
    };
  };
  return res.content.count;
});

export const getPlans = createAsyncThunk(GET_PLANS, async () => {
  const res = (await service.get(apiEndpoints.PLANS)) as PlansResponseD;
  const {
    content: { plans },
  } = res;

  setLocalStorageItem(localStorageKeys.PLANS, JSON.stringify(plans));

  const recom = plans.find((item) => item.is_recommended === true);

  return {
    plans: plans,
    recommendedPlans: recom,
    response: res,
  };
});
