
import { UPDATE_LAYOUT, MAP_LAYOUT, PAGES_LIST, UNDO, REDO, COL_LAYOUT, UPDATE_DATA,  RESET_REDO, RESET_UNDO, UPDATE_DASHBOARD_CUSTOMISATION } from '../types/types'
import {getLocalStorageItem} from '../../utils/helperFunctions/localStorage'


const initialState = {
    layouts: [],
    pages: [],
    pastLayout: [],
    futureLayout: [],
    customisations:  {
        // rounded: {
        //    borderRadius: 0,
        //     type: "overall",
        //     borderTopLeftRadius: 20,
        //     borderTopRightRadius: 10,
        //     borderBottomRightRadius: 10,
        //     borderBottomLeftRadius: 10,
        // },
        // image: {
        //     isApplied: false,
        //     url: "",
        //     fileName: "",
        //     opacity: 1,
        //     fit: "fill",
        // },
        // canvasColor: {
        //     isApplied: false,
        //     color: "#DFDFDFFF",
        // },
        // dropShadow: {
        //     isApplied: false,
        //     shadow: {
        //         X: 2,
        //         Y: 2,
        //         spreadRadius: 1,
        //         blurRadius: 3,
        //     },
        //     shadowColor: "#00000040"
        // },
        // stroke: {
        //     strokeWidth: 1,
        //     strokeColor: "#e8e8e8",
        // }
    }

}


const builderReducer = (state = initialState, action) => {

    switch (action.type) {
        case UPDATE_LAYOUT:
            const filteredLayouts = action.payload.filter(layout => layout.i !== '__dropping-elem__')
            const addPastArray = state.pastLayout
            let activeScreenId = getLocalStorageItem('currentScreenId');
            const existingPastLayoutItem = addPastArray.find(ele => ele.id == activeScreenId);
            if (existingPastLayoutItem) {
                addPastArray.forEach(item => {
                    if (item.id == activeScreenId) {
                        item.layout.push(state.layouts.filter(layout => layout.i !== '__dropping-elem__'))
                    }
                })
            } else {

                addPastArray.push({ id: activeScreenId, layout: [state.layouts.filter(layout => layout.i !== '__dropping-elem__')] })
            }
            return {
                ...state,
                layouts: filteredLayouts,
                pastLayout: addPastArray
            }
        case MAP_LAYOUT:
            let newLayouts = []
            action.payload.map((item) => {
                newLayouts.push(item)
            })

            return {
                ...state,
                layouts: [...action.payload]
            }
        case UPDATE_DATA:
            let newLayout = []
            action.payload.map((item) => {
                newLayout.push(item)
            })

            return {
                ...state,
                layouts: [...action.payload]
            }
        case COL_LAYOUT:
            return {
                ...state,
                layouts: action.payload
            }
        case PAGES_LIST:
            return {
                ...state,
                pages: action.payload
            }
        case UNDO:
            const newFutureLayout = state.futureLayout;
            let activeScreen = getLocalStorageItem('currentScreenId');
            const existingFutureLayout = newFutureLayout.find(ele => ele.id == activeScreen);
            if (existingFutureLayout) {
                newFutureLayout.forEach(item => {
                    if (item.id == activeScreen) {
                        item.layout.push(state.layouts)
                    }
                })
            } else {

                newFutureLayout.push({ id: activeScreen, layout: [state.layouts] })
            }
            const newPast = state.pastLayout;

            const existingPastLayout = newPast.find(ele => ele.id == activeScreen);
            const newPresent = existingPastLayout.layout[existingPastLayout.layout.length - 1]

            newPast.forEach(item => {
                if (item.id == activeScreen) {
                    item.layout.splice(-1)
                }
            })

            return {
                pastLayout: newPast,
                customisations:state?.customisations,
                layouts: newPresent,
                futureLayout: newFutureLayout,
                pages: state.pages
            }
        case REDO:
            let currentScreen = getLocalStorageItem('currentScreenId');
            const currentFutureLayout = state.futureLayout
            const currentFutureLayoutItem = currentFutureLayout.find(ele => ele.id == currentScreen);

            const nextPresent = currentFutureLayoutItem.layout[currentFutureLayoutItem.layout.length - 1]
            currentFutureLayout.forEach(item => {
                if (item.id == currentScreen) {
                    item.layout.splice(-1)
                }
            })
            const newFuture = currentFutureLayout;
            const currentPastLayout = state.pastLayout.find(ele => ele.id == currentScreen);
            let nextPast = state.pastLayout

            if (currentPastLayout) {
                nextPast.forEach(item => {
                    if (item.id == currentScreen) {
                        item.layout.push(state.layouts)
                    }
                })
            }
            return {
                pastLayout: nextPast,
                customisations:state?.customisations,
                layouts: nextPresent,
                futureLayout: newFuture,
                pages: state.pages
            }

        case RESET_UNDO:
            return {
                ...state,
                pastLayout: action.payload
            }

        case RESET_REDO:
            return {
                ...state,
                futureLayout: action.payload
            }

            case UPDATE_DASHBOARD_CUSTOMISATION:
                return {
                    ...state,
                    customisations: action.payload
                }
        default:
            return state
    }
}

export {
    builderReducer
}