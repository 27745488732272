import React from 'react'
import CrossIcon from "../../assests/icons/cancel.svg";
import { connect } from 'react-redux';

function Notification({
  showChatbot,
  showBotInfo,
  setBotLocalStorage,
  unreadCount
}) {
  return (
    <div className='mini-notification-main-container'>
      {!showChatbot && unreadCount != 0 && <div className='mini-closed-notification'><span>{unreadCount}</span></div>}
      {
        showBotInfo && <div className='mini-notification-container'>
          <div className='all-notification-stack'>
            <div className='mini-notification'><span>Hi! I’m Mokkup Bot 🤖. On what topic can I assist you with?</span></div>
          </div>
          <div className='close' onClick={() => { setBotLocalStorage(false) }}><img draggable={false} src={CrossIcon} alt="" srcSet="" /></div>
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.userDetailsReducers.userDetails,
    isPaidSubscription: state.userDetailsReducers.isPaidSubscription,
    unreadCount: state.userDetailsReducers.unreadCount,
    ...state
  }
}
export default connect(mapStateToProps)(Notification);
