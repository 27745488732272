import { UPDATE_THEME, UPDATE_DETAILSVIEW, POPUP_ID, TABLE_RESIZE, DEFAULT_ELEMENTS, TARGET_COLUMN, STACKED_100, UPDATE_THEME_LIST, ENABLE_DESTROY, CURRENT_IMAGE, UPDATE_DROP_TARGET, SHOW_TEMPLATE_MODAL, TUTORIALS_USE_TEMPLATE, SHOW_PREVIEW, DISABLE_TOOLTIP, SET_DASHBOARD_TITLE, CHANGE_HEIGHT, MAP_LAYOUT_SAVE, CHANGE_ELEMENT_THEME, CUSTOM_THEME_LIST } from "../types/types";


const initialState = {
    view: {},
    popupId: "",
    theme: {
        isChanged: false,
        id: 0,
        primary: "",
        palette: [],
        contrast: []
    },
    isTableResize: false,
    defaultElements: [],
    columnToTarget: '',
    isStacked100: false,
    themeList: [],
    customThemeListGlobal: [],
    isDestroyEnabled: true,
    currentImage: "",
    dropTarget: "",
    showTemplateModal: false,
    tutorialsUseTemplate: false,
    showPreview: false,
    isTooltipDisabled: false,
    changeHeight: true,
    isSetDashboardTitle: false,
    isMapLayoutSave: false,
    changeElementTheme: {
        tab: null, element: false
    }
}


const updateDetailsReducer = (state = initialState, action) => {
    switch (action.type) {

        case UPDATE_THEME:
            return {
                ...state,
                theme: action.payload
            }
        case UPDATE_DETAILSVIEW:
            return {
                ...state,
                view: action.payload
            }
        case POPUP_ID:
            return {
                ...state,
                popupId: action.payload
            }
        case TABLE_RESIZE:
            return {
                ...state,
                isTableResize: action.payload
            }
        case DEFAULT_ELEMENTS:
            return {
                ...state,
                defaultElements: action.payload
            }
        case TARGET_COLUMN:
            return {
                ...state,
                columnToTarget: action.payload
            }
        case STACKED_100:
            return {
                ...state,
                isStacked100: action.payload
            }

        case UPDATE_THEME_LIST:
            return {
                ...state,
                themeList: action.payload
            }

        case CUSTOM_THEME_LIST:
            return {
                ...state,
                customThemeListGlobal: action.payload
            }

        case ENABLE_DESTROY:
            return {
                ...state,
                isDestroyEnabled: action.payload
            }
        case CURRENT_IMAGE:
            return {
                ...state,
                currentImage: action.payload
            }
        case UPDATE_DROP_TARGET:
            return {
                ...state,
                dropTarget: action.payload
            }
        case SHOW_TEMPLATE_MODAL:
            return {
                ...state,
                showTemplateModal: action.payload
            }
        case TUTORIALS_USE_TEMPLATE:
            return {
                ...state,
                tutorialsUseTemplate: action.payload
            }
        case SHOW_PREVIEW:
            return {
                ...state,
                showPreview: action.payload
            }

        case DISABLE_TOOLTIP:
            return {
                ...state,
                isTooltipDisabled: action.payload
            }
        case SET_DASHBOARD_TITLE:
            return {
                ...state,
                isSetDashboardTitle: action.payload
            }
        case CHANGE_HEIGHT:
            return {
                ...state,
                changeHeight: action.payload
            }
        case MAP_LAYOUT_SAVE:
            return {
                ...state,
                isMapLayoutSave: action.payload
            }
        case CHANGE_ELEMENT_THEME:
            return {
                ...state,
                changeElementTheme: action.payload
            }
        default:
            return state
    }
}

export {
    updateDetailsReducer
}
