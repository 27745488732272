import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
// @ts-ignore
import NProgress from "nprogress";
import * as Sentry from "@sentry/react";
import store from "../redux/store";
import {
  checkUserSession,
  checkFeedback401,
} from "../redux/actions/userAction";
import { apiEndpoints } from "./constants/apiEndpoints";
import { localStorageKeys } from "./constants/localStorageKeys";
import { baseURL } from "./helperFunctions/envLinks";
import { getLocalStorageItem } from "./helperFunctions/localStorage";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  progress?: boolean;
  headers?: {
    Authorization?: string;
    workspace?: string;
    "Tz-Value"?: string;
  };
  _retry?: boolean;
}

const service: AxiosInstance = axios.create({
  baseURL: baseURL(),
});

let isRefreshing: boolean = false;
let failedQueue: Array<(token: string) => void> = [];

async function refreshToken(): Promise<string> {
  try {
    const response: { data: { content: { access_token: string } } } =
      await axios.post(`${baseURL()}${apiEndpoints.REFRESH}`, {
        refresh: getLocalRefreshToken(),
      });
    const { access_token } = response?.data?.content;
    localStorage.setItem(localStorageKeys.ACCESS_TOKEN, access_token);
    isRefreshing = false;
    failedQueue.forEach((prom) => prom(access_token));
    failedQueue = [];
    return access_token;
  } catch (err) {
    isRefreshing = false;
    store.dispatch(checkUserSession(true));
    throw err;
  }
}

function getLocalAccessToken(): string | null {
  return getLocalStorageItem(localStorageKeys.ACCESS_TOKEN);
}

function getLocalRefreshToken(): string | null {
  return getLocalStorageItem(localStorageKeys.REFRESH_TOKEN);
}

service.interceptors.request.use(
  (config: CustomAxiosRequestConfig): CustomAxiosRequestConfig => {
    let { url, progress } = config;
    if (progress !== false) {
      NProgress.start();
    }
    NProgress.configure({ showSpinner: false });
    const authToken = getLocalAccessToken();
    const workspaceId = getLocalStorageItem(localStorageKeys.WORKSPACEID);
    if (authToken) {
      config.headers = config.headers || {};
      config.headers["Authorization"] = "Bearer " + authToken;
      config.headers["workspace"] = workspaceId || "";
      config.headers["Tz-Value"] =
        Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    return config;
  },
  (error: AxiosError): Promise<AxiosError> => Promise.reject(error)
);

service.interceptors.response.use(
  (response: AxiosResponse): any => {
    NProgress.done();
    store.dispatch(checkFeedback401(false));
    if (response?.headers["mfa-secret-key"]) {
      return response;
    } else {
      return response.data;
    }
  },
  async (error: {
    response: { data: { message: string }; status: number };
    config: CustomAxiosRequestConfig;
  }): Promise<any> => {
    const originalRequest = error.config as CustomAxiosRequestConfig;
    NProgress.done();
    if (error.response) {
      Sentry.captureException(error?.response?.data?.message);
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        if (getLocalRefreshToken()) {
          if (!isRefreshing) {
            isRefreshing = true;
            try {
              const token = await refreshToken();
              return service(originalRequest);
            } catch (_error) {
              store.dispatch(checkFeedback401(true));
              store.dispatch(checkUserSession(true));
              throw _error;
            }
          } else {
            return new Promise((resolve, reject) => {
              failedQueue.push((token: string) => {
                if (originalRequest.headers) {
                  originalRequest.headers["Authorization"] = "Bearer " + token;
                }
                resolve(service(originalRequest));
              });
            });
          }
        } else {
          store.dispatch(checkFeedback401(true));
          store.dispatch(checkUserSession(true));
        }
      }
      // Handle other error statuses if needed
      if (error.response.status === 403 && error.response.data) {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
      if (error.response.status === 404 && error.response.data) {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
      if (error.response.status === 400 && error.response.data) {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
      if (error.response.status === 409 && error.response.data) {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
      if (error.response.status === 500 && error.response.data) {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export default service;
