import React from 'react'
import PageLoader from '../assests/images/page-loader.gif'

function Fallback() {

  return (
    <div className='loader-gif'><img draggable={false} src={PageLoader} alt="" /></div>
  )
}

export default Fallback