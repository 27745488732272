import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/nprogress/nprogress.css'
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/font-awesome/css/font-awesome.min.css'
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';
import './index.scss';
import App from './app';
import { Provider } from "react-redux";
import reportWebVitals from './reportWebVitals';
import store from './redux/store'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { googleLoginId } from './utils/helperFunctions/envLinks'
import ErrorBoundary from './errorBoundary.js';

// YJS SETUP
// import { provider } from './utils/yjsSetup';

// provider.on('status', (event) => {
//   console.log("YJS socket status: ", event.status);
// });

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { cloneDeep } from 'lodash';

//Polyfill for structuredClone using loadash's cloneDeep
if (typeof structuredClone === 'undefined') {
  window.structuredClone = (obj) => {
    return cloneDeep(obj);
  }
}

ReactDOM.render(
  <React.StrictMode>

    <Provider store={store} >
      <GoogleOAuthProvider clientId={googleLoginId()}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
