import React, { useState } from 'react';
import { connect } from 'react-redux'
import BotIcon from '../../assests/icons/bot-circle-icon.svg'
import ChevronRightIcon from '../../assests/icons/chevron_right.svg'
import NoMessage from '../../assests/icons/no-messages.svg'
import LazyLoader from '../lazyLoading/shimmerGrid';
import { Button, Col, Row } from 'reactstrap';
import { useEffect } from 'react';
import service from '../../utils/service';
import { apiEndpoints } from '../../utils/constants/apiEndpoints';
import moment from 'moment';
import ErrorApi from '../../helperComponents/errorApi';


const RecentMessages = ({
    setShowChat,
    userDetails,
    showChat,
    setOldChat,
    status,
    setStatus,
    chatId,
    setChatId,
    setFeedback,
    setUnread,
}) => {

    const [loader, setLoader] = useState(false);

    const [messages, setMessages] = useState([]);

    const [error, setError] = useState(false);

    const [totalCount, setTotalCount] = useState(0);

    const [page, setPage] = useState(1);

    const [filter, setFilter] = useState('all');

    const [list, setList] = useState([])

    const openConvo = (item) => {
        setFeedback(false)
        setOldChat(item.id); setStatus(item.status); setShowChat(true);
        setChatId(item.id)
        if (item.unread_count) {
            setUnread(true)
        } else {
            setUnread(false)
        }
    }

    const getRecentConversations = () => {
        let type = filter == 'all' ? '' : filter == 'read' ? 'true' : 'false'
        setLoader(true)
        service.get(`${apiEndpoints.CHATBOT_CONVERSATIONS}?per_page_items=5&page=${page}&is_read=${type}`, { progress: false }).then((res) => {
            setMessages(res.content.conversations)
            setTotalCount(res.content.count)
            setLoader(false)
            setError(false)

        }).catch((error) => {
            setLoader(false)
            setError(true)
        })
    }

    useEffect(() => {
        getRecentConversations()
    }, [showChat, page, filter])

    useEffect(() => {
        setList([])
    }, [filter])

    useEffect(() => {
        let arr = list
        messages.forEach((ele) => {
            arr.push(ele)
        })
        setList(arr)
    }, [messages])

    return (
        <div className='chatbot-history'>
            <p>Recent Messages</p>
            <p className='helper-text mt-1'>Pick up where you left off or check your recent messages</p>
            <div className='chat-filters'>
                <Button onClick={() => { setFilter('all') }} className={filter == "all" ? 'active' : ''} >All</Button>
                <Button onClick={() => { setFilter('unread') }} className={filter == "unread" ? 'active' : ''} >Unread</Button>
                <Button onClick={() => { setFilter('read') }} className={filter == "read" ? 'active' : ''} >Read</Button>
            </div>
            <div className='recent-messages'>

                {
                    loader ?
                        <LazyLoader view="chatbot-recent-msg-list-lazy" />
                        : error ? <ErrorApi chatbotError={"chatbotError"} /> :
                            <>
                                {
                                    list?.length ? list?.map((item, index) => {
                                        return <>
                                            <div className='message'>
                                                {(item?.last_message?.is_bot_response) ?
                                                    <img draggable={false} src={BotIcon} className="who" alt="send-image" />
                                                    : <div className="intials">
                                                        {userDetails.first_name && userDetails.first_name.charAt(0) + userDetails.last_name.charAt(0)}
                                                    </div>
                                                }
                                                <div className='d-flex  w-100  justify-content-between align-items-center pointer' onClick={() => { openConvo(item) }}>
                                                    <div style={{ width: '90%' }}>
                                                        <p className={`last-message ${item?.unread_count > 0 ? '' : 'read-text'}`}>
                                                            {(item?.status == 'Closed' && item.title) ? item.title : item?.last_message?.payload[0].contentType == "ImageResponseCard" ? item?.last_message?.payload[0]?.imageResponseCard?.title : item?.last_message?.payload[0].content}
                                                        </p>
                                                        <p className='helper-text'>
                                                            {item?.last_message?.is_bot_response ? 'Bot' : 'You'}&nbsp;&#183;&nbsp;{moment.utc(item?.last_message?.created_at).local().fromNow()}
                                                        </p>
                                                    </div>
                                                    {item?.unread_count ? <div className='count'><span>{item?.unread_count}</span></div> : <img draggable={false} src={ChevronRightIcon} className="send-image-right-arrow" alt="send-image" />}
                                                </div>
                                            </div>
                                        </>
                                    }) : <div className='no-message'>
                                        <img draggable={false} src={NoMessage} className="" alt="send-image" />
                                        <div>
                                            <div>
                                                <p>Sorry! No {filter && filter == "all" ? "" : filter} messages</p>
                                                <p className='helper-text'>You need to have {filter && filter == "all" ? "" : filter} conversations in order to see messages in this menu.</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(list.length < totalCount) ? <div className='load-more pointer' onClick={() => setPage(page + 1)}>
                                    <p>  Load more messages</p>
                                </div> : ''}
                            </>
                }
            </div>

        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetailsReducers.userDetails,
        isPaidSubscription: state.userDetailsReducers.isPaidSubscription,
        userSession: state.userDetailsReducers.userSession,
        ...state
    }
}

export default connect(mapStateToProps)(RecentMessages);

