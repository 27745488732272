import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip';
import HelpQuestionMark from "../../assests/images/Helpcenter-app.svg";
import ChatBotDownArrow from "../../assests/images/chat-bot-down-arrow.svg";
import Homepage from './homepage';
import Notification from './notification';
import { getLocalStorageItem, setLocalStorageItem } from '../../utils/helperFunctions/localStorage';
import { localStorageKeys } from '../../utils/constants/localStorageKeys';
import { getUnreadConvo } from '../../redux/actions/userAction';
import { useLocation } from 'react-router-dom';
import { toggleChatbot } from '../../redux/actions/dashboardAction';
import { setChatbotClosure } from '../../redux/actions/commentAction';



const Chatbot = ({
    userDetails,
    userSession,
    openChatbot,
}) => {

    let dispatch = useDispatch()

    const location = useLocation();

    const [showChatbot, setShowChatbot] = useState(false);

    //Work in progress for closing the chatbot
    const { chatbotClosureState } = useSelector(state => state.commentReducer)

    const [feedback, setFeedback] = useState(false);

    const [showchat, setShowChat] = useState(false);

    const [chatId, setChatId] = useState();

    const [reportingBug, setReportingBug] = useState(false);


    const [newChat, setNewChat] = useState(false);

    const [oldChat, setOldChat] = useState(false);

    const [status, setStatus] = useState('')

    const [unread, setUnread] = useState(false)

    const [routeName, setRouteName] = useState();

    const [showBotInfo, setShowBotInfo] = useState(JSON.parse(getLocalStorageItem(localStorageKeys.SHOW_BOT_INFO)))

    const setBotLocalStorage = (state) => {
        setLocalStorageItem(localStorageKeys.SHOW_BOT_INFO, state);
        setShowBotInfo(state)
    }

    useEffect(() => {
        let localBotValue = JSON.parse(getLocalStorageItem(localStorageKeys.SHOW_BOT_INFO))
        if (localBotValue || localBotValue == false) {
            setShowBotInfo(localBotValue)
        } else {
            setBotLocalStorage(true)
        }
    }, [userDetails])

    if ((Object.keys(userDetails)?.length == 0 || userSession) && showChatbot) {
        setShowChatbot(false)
        setShowChat(false)
    }
    // useEffect(() => {
    //     let token = getLocalStorageItem(localStorageKeys.ACCESS_TOKEN)
    //     if (!showChatbot && token) {
    //         if (window.getComputedStyle(document.querySelectorAll((location?.pathname?.includes('/screen/') || location?.pathname?.includes('/share/')) ? '.new-chatbot' : '.chatbot' )).display != "none")
    //             dispatch(getUnreadConvo())
    //     }
    // }, [showChatbot])

    useEffect(() => {
        let token = getLocalStorageItem(localStorageKeys.ACCESS_TOKEN);
        if (!showChatbot && token) {
            const elements = document.querySelectorAll(
                (location?.pathname?.includes('/screen/') || location?.pathname?.includes('/share/')) ?
                '.new-chatbot' :
                '.chatbot'
            );
            let isDisplayed = false;
            elements.forEach(element => {
                if (window.getComputedStyle(element).display !== "none") {
                    isDisplayed = true;
                }
            });
            if (isDisplayed) {
                dispatch(getUnreadConvo());
            }
        }
    }, [showChatbot]);

    useEffect(() => {
        if (routeName || chatbotClosureState) {
            setShowChatbot(false)
            dispatch(setChatbotClosure(false))
        }
        if(chatbotClosureState){
            setBotLocalStorage(false)
        }
    }, [routeName, chatbotClosureState])

    useEffect(() => {
        const { pathname } = location;
        setRouteName(location);
    }, [location.pathname]);

    useEffect(() => {
        if(openChatbot == true){
            setShowChatbot(true)
            dispatch(toggleChatbot(false))
        } 
    },[openChatbot])

    return (
        <>
            <div className={(location?.pathname?.includes('/screen/') || location?.pathname?.includes('/share/')) ? 'new-chatbot' : 'chatbot' }>
                <div className='chatbot-arrow' style={userSession ? { zIndex: 1 } : { zIndex: 99 }}>
                    <ReactTooltip id="chatbot" /><span style={{ userSelect: "none" }} onClick={() => { setShowChatbot(!showChatbot); setBotLocalStorage(false) }} data-for="chatbot" data-place="top" data-class="tooltip-headers" data-effect="solid" data-background-color="#060515" data-tip={showChatbot ? "" : "Chat Bot"}><img className='new-chatbot-style' draggable={false} src={showChatbot ? ChatBotDownArrow : HelpQuestionMark} alt="chat bot" /></span>
                    <Notification showChatbot={showChatbot} showBotInfo={showBotInfo} setBotLocalStorage={setBotLocalStorage} />
                </div>
                {
                    showChatbot && <div className={`chatbot-main-container ${!showchat ? "scroll-chatbot" : "scroll-chatbot"}`} id="scroll-chatbot">
                        <Homepage setFeedback={setFeedback} feedback={feedback} showchat={showchat} setShowChat={setShowChat} setReportingBug={setReportingBug} reportingBug={reportingBug}
                            oldChat={oldChat} newChat={newChat} setOldChat={setOldChat} setNewChat={setNewChat} setShowChatbot={setShowChatbot}
                            status={status} setStatus={setStatus} showChatbot={showChatbot} chatId={chatId} setChatId={setChatId} unread={unread} setUnread={setUnread} />
                    </div>
                }
            </div>
        </>
    );
}


const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetailsReducers.userDetails,
        isPaidSubscription: state.userDetailsReducers.isPaidSubscription,
        userSession: state.userDetailsReducers.userSession,
        openChatbot : state.dashboardReducer.openChatbot,
        ...state
    }
}
export default connect(mapStateToProps)(Chatbot);

