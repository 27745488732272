import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeaturesD, UserDetailsD } from "./userDetailsTypes";
import {
  getFeatures,
  getPlans,
  getUnreadConvo,
  getUserInfo,
} from "./userDetailsThunks";
import {
  PlanD,
  PlansResponseD,
} from "@/redux-toolkit/features/userDetails/userDetailsTypes";

type SubscriptionD = {};

type UserStateD = {
  userDetails: UserDetailsD;
  subscription: SubscriptionD;
  plans: PlanD[];
  recommendedPlan: PlanD | undefined;
  upgradePopup: boolean;
  isPaidSubscription: boolean;
  features: FeaturesD;
  proTemplates: number[];
  userSession: boolean;
  trialSuccess: boolean;
  isTrialConsumed: boolean;
  isTrialMember: boolean;
  unreadCount: number;
  isShowFeedbackPopup: boolean;
  check401feedback: boolean;
};

const initialState: UserStateD = {
  userDetails: {} as UserDetailsD,
  subscription: {} as SubscriptionD,
  plans: [],
  recommendedPlan: {} as PlanD,
  upgradePopup: false,
  isPaidSubscription: false,
  features: {} as FeaturesD,
  proTemplates: [],
  userSession: false,
  trialSuccess: false,
  isTrialConsumed: false,
  isTrialMember: false,
  unreadCount: 0,
  isShowFeedbackPopup: false,
  check401feedback: true,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserDetails: (state) => ({
      ...initialState,
    }),
    setUserProfile(state, action: PayloadAction<UserDetailsD>) {
      state.userDetails = action.payload;
    },
    setUserSubscription(state, action: PayloadAction<SubscriptionD>) {
      state.subscription = action.payload;
    },
    setPlans(state, action: PayloadAction<PlanD[]>) {
      state.plans = action.payload;
    },
    setRecommendedPlan(state, action: PayloadAction<PlanD>) {
      state.recommendedPlan = action.payload;
    },
    setUpgradePopup(state, action: PayloadAction<boolean>) {
      state.upgradePopup = action.payload;
    },
    toggleUpgradePopup(state, action: PayloadAction<boolean | undefined>) {
      const type = action?.payload;
      state.upgradePopup = type ? false : !state.upgradePopup;
    },
    setIsPaidSubscription(state, action: PayloadAction<boolean>) {
      state.isPaidSubscription = action.payload;
    },
    setIsTrialConsumed(state, action: PayloadAction<boolean>) {
      state.isTrialConsumed = action.payload;
    },
    setIsTrialMember(state, action: PayloadAction<boolean>) {
      state.isTrialMember = action.payload;
    },
    setUserFeatures(state, action: PayloadAction<FeaturesD>) {
      state.features = action.payload;
    },
    setProTemplates(state, action: PayloadAction<number[]>) {
      state.proTemplates = action.payload;
    },
    setUserSession(state, action: PayloadAction<boolean>) {
      state.userSession = action.payload;
    },
    setTrialSuccess(state, action: PayloadAction<boolean>) {
      state.trialSuccess = action.payload;
    },
    setUnreadCount(state, action: PayloadAction<number>) {
      state.unreadCount = action.payload;
    },
    setFeedbackPopup(state, action: PayloadAction<boolean>) {
      state.isShowFeedbackPopup = action.payload;
    },
    setFeedbackCheck401(state, action: PayloadAction<boolean>) {
      state.check401feedback = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getFeatures.fulfilled,
      (state, action: PayloadAction<FeaturesD>) => {
        state.features = action.payload;
      }
    );

    builder.addCase(
      getUserInfo.fulfilled,
      (state, action: PayloadAction<UserDetailsD>) => {
        state.userDetails = action.payload;
        state.isPaidSubscription = action.payload.is_pro_member;
        state.isTrialConsumed = action.payload.is_trial_action_taken;
        state.isTrialMember = action.payload.is_trial_member;
      }
    );

    builder.addCase(
      getUnreadConvo.fulfilled,
      (state, action: PayloadAction<number>) => {
        state.unreadCount = action.payload;
      }
    );

    builder.addCase(
      getPlans.fulfilled,
      (
        state,
        action: PayloadAction<{
          plans: PlanD[];
          recommendedPlans: PlanD | undefined;
          response: PlansResponseD;
        }>
      ) => {
        const { plans, recommendedPlans } = action.payload;
        state.plans = plans;
        state.recommendedPlan = recommendedPlans;
      }
    );
  },
});

export const {
  resetUserDetails,
  setUserProfile,
  setUserSubscription,
  setPlans,
  setRecommendedPlan,
  setUpgradePopup,
  setIsPaidSubscription,
  setIsTrialConsumed,
  setIsTrialMember,
  setUserFeatures,
  setProTemplates,
  setUserSession,
  setTrialSuccess,
  setUnreadCount,
  setFeedbackPopup,
  setFeedbackCheck401,
  toggleUpgradePopup,
} = userSlice.actions;

export default userSlice.reducer;
