// User profile thunks
export const GET_FEATURES = "user/getFeatures";
export const GET_PLANS = "user/getPlans";
export const GET_USER_INFO = "user/getUserInfo";
export const GET_UNREAD_CONVO = "user/getUnreadConvo";

// Comment thunks
export const GET_SHARED_MEMBER_LIST = "comments/getSharedMemberList";
export const GET_SHARED_PROJECT_DATA = "comments/getSharedProjectData";
export const GET_ROLE_OF_USER = "comments/getRoleOfUser";
export const GET_ALL_PROJECTS_SHARED_MEMBERS_LIST =
  "comments/getAllProjectsSharedMembersList";

//
export const DESTROY_SESSION = "DESTROY_SESSION";
