import { Action, combineReducers, createAction } from "@reduxjs/toolkit";
import commentSlice from "./features/comments/commentsSlice";
import builderSlice from "./features/mokkupBuilder/builderSlice";
import userDetailsSlice from "./features/userDetails/userDetailsSlice";
import dashboardSlice from "./features/dashboard/dashboardSlice";
import updateDetailsSlice from "./features/mokkupBuilder/updateDetailsSlice";
import layoutSlice from "./features/mokkupBuilder/layoutSlice";
import { DESTROY_SESSION } from "./types";
import commentSidebarSlice from "./features/comments/commentSidebarSlice";

const appReducer = combineReducers({
  builder: builderSlice,
  updateDetails: updateDetailsSlice,
  dashboard: dashboardSlice,
  comment: commentSlice,
  builderLayout: layoutSlice,
  userDetails: userDetailsSlice,
  commentSidebar: commentSidebarSlice,
});

export type RootReducerStateD = ReturnType<typeof appReducer>;

const rootReducer = (
  state: RootReducerStateD | undefined,
  action: Action<string>
): RootReducerStateD => {
  if (action.type === DESTROY_SESSION) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const resetState = createAction(DESTROY_SESSION);

export default rootReducer;
