import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommentD, ReplyD } from "./commentsTypes";

type CommentSidebarStateD = {
  commentTabs: string;
  search: string;
  screenFilter: string[];
  timeFilter: string;
  openCommentsPage: number;
  openResolvedPage: number;
  readFilter: string;
  openReactions: number | string | null;
  activeComment: CommentD | null | undefined;
  activeReply: ReplyD | null;
  openLoader: boolean;
  resolveLoader: boolean;
  openError: boolean;
  resolveError: boolean;
  restrictApiCall: boolean;
  openCommentList: CommentD[];
  resolvedCommentList: CommentD[];
  openElementComments: boolean;
  editComment: boolean;
  editCommentMode: boolean;
  editReplyMode: boolean;
  replyList: ReplyD[];
  activeReplyCount: number;
  hasMore: boolean;
  resolvedHasMore: boolean;
  openCommentCountForElements: number;
};

const initialState: CommentSidebarStateD = {
  commentTabs: "1",
  search: "",
  screenFilter: [],
  timeFilter: "",
  openCommentsPage: 1,
  openResolvedPage: 1,
  readFilter: "",
  openReactions: null,
  activeComment: null,
  activeReply: null,
  openLoader: false,
  resolveLoader: false,
  openError: false,
  resolveError: false,
  restrictApiCall: false,
  openCommentList: [],
  resolvedCommentList: [],
  openElementComments: false,
  editComment: false,
  editCommentMode: false,
  editReplyMode: false,
  replyList: [],
  activeReplyCount: 0,
  hasMore: true,
  resolvedHasMore: true,
  openCommentCountForElements: 0,
};

const commentSidebarSlice = createSlice({
  name: "commentSidebar",
  initialState,
  reducers: {
    resetCommentSidebarState: () => initialState,
    setCommentTabs(state, action: PayloadAction<string>) {
      state.commentTabs = action.payload;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setScreenFilter(state, action: PayloadAction<string[]>) {
      state.screenFilter = action.payload;
    },
    setTimeFilter(state, action: PayloadAction<string>) {
      state.timeFilter = action.payload;
    },
    setOpenCommentsPage(state, action: PayloadAction<number>) {
      state.openCommentsPage = action.payload;
    },
    setOpenResolvedPage(state, action: PayloadAction<number>) {
      state.openResolvedPage = action.payload;
    },
    setReadFilter(state, action: PayloadAction<string>) {
      state.readFilter = action.payload;
    },
    setOpenReactions(state, action: PayloadAction<number | "comment" | null>) {
      state.openReactions = action.payload;
    },
    setActiveComment(state, action: PayloadAction<CommentD | null>) {
      state.activeComment = action.payload;
    },
    setActiveReply(state, action: PayloadAction<ReplyD | null>) {
      state.activeReply = action.payload;
    },
    setOpenLoader(state, action: PayloadAction<boolean>) {
      state.openLoader = action.payload;
    },
    setResolveLoader(state, action: PayloadAction<boolean>) {
      state.resolveLoader = action.payload;
    },
    setOpenError(state, action: PayloadAction<boolean>) {
      state.openError = action.payload;
    },
    setResolveError(state, action: PayloadAction<boolean>) {
      state.resolveError = action.payload;
    },
    setRestrictApiCall(state, action: PayloadAction<boolean>) {
      state.restrictApiCall = action.payload;
    },
    setOpenCommentList(state, action: PayloadAction<CommentD[]>) {
      state.openCommentList = action.payload;
    },
    setResolvedCommentList(state, action: PayloadAction<CommentD[]>) {
      state.resolvedCommentList = action.payload;
    },
    setOpenElementComments(state, action: PayloadAction<boolean>) {
      state.openElementComments = action.payload;
    },
    setEditComment(state, action: PayloadAction<boolean>) {
      state.editComment = action.payload;
    },
    setEditCommentMode(state, action: PayloadAction<boolean>) {
      state.editCommentMode = action.payload;
    },
    setEditReplyMode(state, action: PayloadAction<boolean>) {
      state.editReplyMode = action.payload;
    },
    setReplyList(state, action: PayloadAction<ReplyD[]>) {
      state.replyList = action.payload;
    },
    setActiveReplyCount(state, action: PayloadAction<number>) {
      state.activeReplyCount = action.payload;
    },
    setHasMore(state, action: PayloadAction<boolean>) {
      state.hasMore = action.payload;
    },
    setResolvedHasMore(state, action: PayloadAction<boolean>) {
      state.resolvedHasMore = action.payload;
    },
    setOpenCommentCountForElements(state, action: PayloadAction<number>) {
      state.openCommentCountForElements = action.payload;
    },
  },
});

export const {
  resetCommentSidebarState,
  setCommentTabs,
  setSearch,
  setScreenFilter,
  setTimeFilter,
  setOpenCommentsPage,
  setOpenResolvedPage,
  setReadFilter,
  setOpenReactions,
  setActiveComment,
  setActiveReply,
  setOpenLoader,
  setResolveLoader,
  setOpenError,
  setResolveError,
  setRestrictApiCall,
  setOpenCommentList,
  setResolvedCommentList,
  setOpenElementComments,
  setEditComment,
  setEditCommentMode,
  setEditReplyMode,
  setReplyList,
  setActiveReplyCount,
  setHasMore,
  setResolvedHasMore,
  setOpenCommentCountForElements,
} = commentSidebarSlice.actions;

export default commentSidebarSlice.reducer;
