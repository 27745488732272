import React from "react";
import ElementLoad from "../assests/images/element-load.gif";

type PropsD = {
  elements?: boolean;
  popup?: boolean;
  chatbotError?: boolean;
  message?: string;
  error?: boolean;
  height?: string;
  fetchData?: () => void;
};

function ErrorApi({
  elements = false,
  popup = false,
  chatbotError = false,
  message = "",
  error = false,
  height,
  fetchData,
}: PropsD) {
  return (
    <div
      style={height ? { height } : {}}
      className={`no-results ${message && message.length ? "geomap" : ""} ${
        elements ? "error-api" : ""
      } ${popup ? "popup" : ""} ${chatbotError ? "chatbotError" : ""}`}
    >
      <div className="no-block">
        {message && message.length && !error ? (
          <>
            <img src={ElementLoad} alt="Loading" />
            <p style={{ color: "#000000" }}>{message}</p>
          </>
        ) : (
          <>
            <h3>Oops! Something went wrong.</h3>
            <p>
              {error ? (
                <>
                  Click here to{" "}
                  <span className="try-again" onClick={fetchData}>
                    try again
                  </span>
                  .
                </>
              ) : (
                "Refresh the page or check again in some time."
              )}
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default ErrorApi;
