import localforage from "localforage";
import { builderTransform } from "./transforms/builderTransform";

localforage.config({
  driver: localforage.INDEXEDDB,
  name: "mokkupApp",
  storeName: "reduxPersist",
  description: "Persisted Redux store using IndexedDB",
});

const rootPersistConfig = {
  key: "root",
  storage: localforage,
  whitelist: ["builder"],
  transforms: [builderTransform],
};

export default rootPersistConfig;
