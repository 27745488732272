import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {  setChatbotClosure } from '../../redux/actions/commentAction';
import { apiEndpoints } from '../../utils/constants/apiEndpoints';
import { localStorageKeys } from '../../utils/constants/localStorageKeys';
import { routesConstants } from '../../utils/constants/routes';
import service from '../../utils/service';
import useQuery from '../../utils/hooks/useQuery'
import InvalidLink from '../billing/helperComponents/invalidLink';
import { licenseManagementConstants } from '../billing/helperComponents/constants';


function AcceptTeamInvite({
    match: {
        params: {
            _slug,
            _token
        }
    },
}) {

    const { addToast } = useToasts()

    // const { userDetails, features: { ["no-of-projects"]: noOfProjects, ["your-projects"]: yourProjects }, isPaidSubscription } = useSelector(state => state.userDetailsReducers)

    const history = useHistory()

    const dispatch = useDispatch()

    const [loader, setLoader] = useState(licenseManagementConstants.LOADER_TYPE_LOADING)

    const [error, setError] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    let query = useQuery();


    const handleAcceptInvite = () => {
        service.post(`${apiEndpoints?.WORKSPACE_ME}/${_slug}/requests/${_token}`).then((res) => {
            addToast(res?.message, { appearance: 'success', autoDismiss: "true", autoDismissTimeout: "3000" })
            setError(false)
            localStorage.removeItem(localStorageKeys.JOIN_TEAM_INVITE)

            if(res?.content?.workspace_id){
                history.push(`/billing?type=teams&id=${res?.content?.workspace_id}`)
            }else{
                history.push(`/billing`)
            }

        }).catch((error) => {
            if (!(error?.response.status == 401)) {
                setError(true)
                addToast(error?.response?.data?.message, { appearance: 'error', placement: 'top-center', autoDismiss: "true", autoDismissTimeout: "3000" });
                history.push(routesConstants.DASHBOARD)
                localStorage.removeItem(localStorageKeys.JOIN_TEAM_INVITE)
                
            } else {
                localStorage.setItem(localStorageKeys.JOIN_TEAM_INVITE, `/team/${_slug}/join/${_token}`)
            }
        })
    }


    const joinWorkspace = () => {
        service.post(`${apiEndpoints.WORKSPACE_ME}/${_slug}/join`).then((res) => {
            addToast(res?.message, { appearance: 'success', autoDismiss: "true", autoDismissTimeout: "3000" })
            setError(false)
            localStorage.removeItem(localStorageKeys.JOIN_TEAM_INVITE)
            if (res?.content?.workspace_id) {
                history.push(`/billing?type=teams&id=${res?.content?.workspace_id}`)
            } else {
                history.push(`/billing`)
            }

        }).catch((error) => {
            if (!(error?.response.status == 401)) {
                addToast(error?.response?.data?.message, { appearance: 'error', placement: 'top-center', autoDismiss: "true", autoDismissTimeout: "3000" });
                localStorage.removeItem(localStorageKeys.JOIN_TEAM_INVITE)
                setError(true)
                setErrorMessage(error?.response?.data?.message)
            }
        })
    }

    useEffect(() => {
        if (_slug && _token) {
            setLoader(licenseManagementConstants.LOADER_TYPE_ACCEPT)
            handleAcceptInvite()
        } else if ((window.location.href)?.includes("join")) {
            setLoader(licenseManagementConstants.LOADER_TYPE_ACCEPT)

            joinWorkspace()
        } else {

            setLoader(licenseManagementConstants.LOADER_TYPE_REDIRECT)
            if (_slug) {
                joinWorkspace()
                // openProjectWithComments()
            } else {
                localStorage.removeItem(localStorageKeys.JOIN_TEAM_INVITE)
            }
        }
    }, [_slug, _token])

    useEffect(() => {
        dispatch(setChatbotClosure(true))
    }, [])

    return (
        <>
            {
                error ?
                    <InvalidLink errorMessage={errorMessage} />
                    : <div className='accepting-invite'>
                        {
                            loader && loader == licenseManagementConstants.LOADER_TYPE_LOADING ? "Loading..." : loader == licenseManagementConstants.LOADER_TYPE_ACCEPT ? "Accepting Invite..." : "Redirecting..."
                        }
                    </div>
            }
        </>
    )
}

export default AcceptTeamInvite