import localforage from "localforage"
import { localStorageKeys } from "../constants/localStorageKeys"

export const setLocalStorageItem = (key, value) => {
    localStorage.setItem(key, value)
}

export const getLocalStorageItem = (key) => {
    return localStorage.getItem(key)
}

export const setLocalStorageItemArray = (data) => {
    data.map((item, index) => {
        let localItem = Object.entries(item)
        localStorage.setItem(localItem[0][0], localItem[0][1])
    })
}

export const setDashboardValues = (dashboardId, dashboardName, categoryId, categoryName, screenId, screenName, screenWidth, screenHeight) => {
    localStorage.setItem(localStorageKeys.NEW_DASHBOARD_ID, dashboardId)
    localStorage.setItem(localStorageKeys.DASHBOARD_NAME, dashboardName)
    localStorage.setItem(localStorageKeys.CURRENT_CATEGORY, categoryId)
    localStorage.setItem(localStorageKeys.CATEGORY_NAME, categoryName)
    localStorage.setItem(localStorageKeys.CURRENT_SCREEN_ID, screenId)
    localStorage.setItem(localStorageKeys.CURRENT_SCREEN_NAME, screenName)
    localStorage.setItem(localStorageKeys.FRAME_WIDTH, screenWidth)
    localStorage.setItem(localStorageKeys.FRAME_HEIGHT, screenHeight)
}

export const clearLocalStorage = () => {
    for (const key in localStorageKeys) {
        if(!(key == "USER_FEEDBACK" || key == "VERSION" || key == "SHOW_BOT_INFO" || key == "OPENPROJECTLINK" || key == "JOIN_TEAM_INVITE")){
            localStorage.removeItem(localStorageKeys[key])
        }
    }
}

export const clearIndexedDB = () => {
    localforage.clear().then(() => {
        // console.log('IndexedDB cleared')
    }).catch(err => console.error('Error clearing IndexedDB:', err));
}