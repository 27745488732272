import { CURRENT_TEMPLATE, OPEN_CHATBOT, TEMPLATES_HEADER_ZINDEX } from '../types/types'

const initialState = {
    currentTemplate: 'Marketing',
    headerZindex: false,
    openChatbot : false
}

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case CURRENT_TEMPLATE:
            return {
                ...state,
                currentTemplate: action.payload,
            }
        case TEMPLATES_HEADER_ZINDEX:
            return {
                ...state,
                headerZindex: action.payload,
            }
            case TEMPLATES_HEADER_ZINDEX:
                return {
                    ...state,
                    headerZindex: action.payload,
                }
                case OPEN_CHATBOT:
                    return {
                        ...state,
                        openChatbot : action.payload,
                    }
        default:
            return state
    }
}

export {
    dashboardReducer
}